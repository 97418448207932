import { Component, OnInit, Input } from '@angular/core';
import { Jeu } from '@models/jeu';
import { ProduitService } from '@services/produit.service';
import { Produit } from '@models/produit';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-produits-salon',
  templateUrl: './edit-produits-salon.component.html',
  styleUrls: ['./edit-produits-salon.component.scss']
})
export class EditProduitsSalonComponent implements OnInit {
  @Input() jeu: Jeu;
  @Input() tour: number;

  produits: Produit[] = [];
  allProduitsInnovation: Produit[] = [];

  loadingAllProduits: boolean = false;
  currentProduit: Produit;
  selectProduitId = new FormControl('');

  loadingAddProduit: boolean = false;

  constructor(
    private produitService: ProduitService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getProduitsTour();
    this.getAllProduits();
  }

  getProduitsTour(){
    this.produitService.getProduitsTour(this.jeu.id, this.tour).subscribe(produits => {
      this.produits = produits;
    });
  }

  getAllProduits(){
    this.loadingAllProduits = true;
    this.produitService.getAll({"type.slug":"investissement-innovation", "exists[archived]": false}).subscribe(produits => {
      this.allProduitsInnovation = produits;
      this.loadingAllProduits = true;
    });
  }

  ajouterProduit(){
    console.log('selectedProduitId', this.selectProduitId);

    this.loadingAddProduit = true;
    this.produitService.addProduitToSalon(this.jeu.id, this.tour, this.selectProduitId.value).subscribe(produit => {
      this.produits.push(produit);
      this.selectProduitId.setValue('');
      this.loadingAddProduit = false;
    });

  }

  onDeletedProduit(produit){
    console.log('produit', produit);

    // Enlever le Produit du front-end
    this.produits = this.produits.filter(p => p.id != produit.id);
  }

  goToAjouterProduit(){
    this.router.navigate(['/produits/create'], { queryParams: { returnUrl: this.router.url}});
  }
}
