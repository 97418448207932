<div>
    <mat-table class="lessons-table" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
            <mat-cell *matCellDef="let produit">{{ produit.id }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="nom">
            <mat-header-cell *matHeaderCellDef>{{ 'nom_du_produit' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let produit">
              <img [src]=contentUrl(produit.photo) width="64px">
              {{ produit.nom }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let produit">
                <button mat-icon-button color="warning" [routerLink]="['/produits/edit', produit.id]">
                    <mat-icon>build</mat-icon>
                </button>
                <button mat-icon-button color="danger" (click)="triggerModal(modalData)">
                    <mat-icon>close</mat-icon>
                </button>
                <ng-template #modalData let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal">Supprimer </h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p><strong>Voulez-vous vraiment supprimer? <span class="text-primary">"{{produit.nom}}"</span></strong></p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">Fermer</button>
                      <button type="button" mat-raised-button color="primary" (click)="onDelete(produit.id); modal.close('Save click')">Confirmer la suppression</button>
                    </div>
                  </ng-template>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

    </mat-table>
    <mat-paginator [length]="dataSource.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>
    <div class="row">
        <div class="col text-center">
          <select  id="inputType"  (change)="onChangeType($event)"  class="selectpicker" data-style="btn-warning" [(ngModel)]="selectedType">
            <option *ngFor="let type of types" [value]="type.iri" >{{ type.nom }}</option>
          </select>
        </div>
        <div class="col text-center">
          <button type="button" routerLink="/produits/create" mat-raised-button color="primary">{{ 'creer_nouveau_produit' | translate }}</button>
        </div>
      </div>
    </div>

