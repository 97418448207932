<div class="division-data">
  <div class="division-left">
    <div class="user-profil">
      <div class="user-photo">
        <img class="user-photo" [src]="imageUrl" alt="logo" (click)="fileInput.click()">
        <input type="file" class="form-control" id="inputTelephone" (change)="onSelectImage($event)" #fileInput hidden>
      </div>
    </div>
  </div>
  <div class="division-right">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                    <label for="inputName">Nom *</label>
                    <input type="text" value="" name="lastname" id="inputName" class="form-control" placeholder="Entrer votre nom" required="" autofocus="" formControlName="lastname">
                    <i class="icon-email"></i>
                  </div>

                  <div class="form-group">

                    <label for="inputFirstName">Prénoms *</label>
                    <input type="text" value="" name="firstname" id="inputFirstName" class="form-control" placeholder="Entrer votre prénom" required="" autofocus="" formControlName="firstname">
                    <i class="icon-email"></i>
                  </div>

                  <div class="form-group">

                    <label for="inputEmail">Email *</label>
                    <input type="email"
                            value=""
                            name="username"
                            id="inputEmail"
                            [ngClass]="{
                              'form-control': true,
                              'border-error': isUsernameToken(),
                              'border-success': !isUsernameToken()
                                                && this.userForm.get('username').value !== null
                                                && this.userForm.get('username').value !== ''
                            }"
                            placeholder="Nom d'utilisateur"
                            required=""
                            autofocus=""
                            formControlName="username">
                    <i class="icon-email"></i>
                    <div class="error" *ngIf="isUsernameToken()">
                      * Cet email est déjà utilisé
                    </div>
                    <div  class="success"
                          *ngIf="!isUsernameToken()
                                  && this.userForm.get('username').value !== null
                                  && this.userForm.get('username').value !== ''
                                ">
                      * Email disponible
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="inputPassword">Mot de passe *</label>
                    <input type="password" name="password" id="inputPassword" class="form-control" placeholder="Mot de passe" required="" autofocus="" formControlName="password">
                    <i class="icon-password"></i>
                  </div>

                  <div class="form-group">
                    <label for="inputConfirmPassword">Confirmez le mot de passe *</label>
                    <input type="password" name="password" id="inputConfirmPassword" [ngClass]="{'border-error': userForm.errors?.passNotMatch}" class="form-control" placeholder="Mot de passe" required="" autofocus="" formControlName="confirm_password">
                    <i class="icon-password"></i>
                    <div class="error" *ngIf="userForm.errors?.passNotMatch">
                      * Les mots de passes ne correspondent pas
                    </div>
                  </div>

                  <button type="submit" value="Créer" mat-raised-button color="primary" [disabled]="userForm.invalid || loading">
                      <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>
                      {{ 'enregistrer' | translate }}
                  </button>
              </div>
          </div>
      </form>
  </div>
</div>
