import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { Annonce } from '@models/annonce';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AnnonceService } from '@services/annonce.service';
import { MediaObjectService } from '@services/media-object.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-annonce-jeu-edit-item',
  templateUrl: './annonce-jeu-edit-item.component.html',
  styleUrls: ['./annonce-jeu-edit-item.component.scss']
})
export class AnnonceJeuEditItemComponent implements OnInit {

  @Input() annonce : any;
  @Input() tour: number;

  @Output() deletedAnnonce = new EventEmitter<number|null>();

  @ViewChild("inputUpload") inputUpload : ElementRef;

  photo : any;
  photoTempUrl : any;
  noPhoto = "/media/routine.PNG";
  uploadedPhoto: boolean = false;
  loading: boolean = false;
  loadingDeleteAnnonce: boolean = false;
  lang: string = 'fr';
  lancement_tour_en = 'fixtures/lancement_tour_en.png';
  lancement_tour_fr = 'fixtures/lancement_tour_fr.png';
  
  constructor(
    private annonceService: AnnonceService,
    private mediaOjbectService: MediaObjectService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.getDefaultLang();
    console.log('language', this.lang);
    this.setupLanguageChangeCallback();
  }

  changeType() {
    console.log("Change detected");
    console.log(this.annonce);
    this.annonce.titre = null;
    this.annonce.description = null;
    this.annonce.image = null;
  }

  update(){
    console.log("Updating: ", this.annonce);
    this.loading = true;
    // this.annonceService.
    if(this.uploadedPhoto){
      this.uploadPhoto().subscribe(
        (resultat) => {
          console.log("Upload reussi");
          console.log(resultat);
          const id = resultat.id;
          this.annonce.image = id;
          console.log(this.annonce);
          this.annonceService.updateAnnonceJeu(this.annonce.id, this.annonce).subscribe(
            (resultat) => {
              console.log("Resultat: ", resultat);
              this.loading = false;
            },
            (error) => {
              console.log("Error: ", error);
              this.loading = false;
            }
          )
        }
      );
    }
    else {
      const annonce = {...this.annonce, image: null };
      console.log(annonce);
      this.annonceService.updateAnnonceJeu(annonce.id, annonce).subscribe(
        (resultat) => {
          console.log("Resultat: ", resultat);
          this.loading = false;
        },
        (error) => {
          console.log("Error: ", error);
          this.loading = false;
        }
      );
    }
  }

  selectPhoto(event){
    const files = event.target.files;
    if(files.length > 0){
      console.log(files);
      this.photoTempUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
      this.uploadedPhoto = true;
      const test = files[0];
      // this.photo = files[0];
      this.photo = test;
      this.annonce = {...this.annonce, image: ""};
    }
  }


  contentUrl(url: string){
    // console.log(this.annonce.image.contentUrl)
    if(this.uploadedPhoto)
      return this.photoTempUrl;
    if(this.annonce.image != undefined)
      return `${environment.apiUrl}/${this.annonce.image.contentUrl}`;
    else
      return `${environment.apiUrl}/${this.noPhoto}`;
  }

  uploadPhoto(): Observable<any>{
    let formData = new FormData();
    formData.append('file', this.photo);
    return this.mediaOjbectService.createMediaObject(formData);
  }

  deleteAnnonce(){
    if(!this.annonce.id){
      this.deletedAnnonce.emit(null);
    }else{
      this.loadingDeleteAnnonce = true;
      this.annonceService.deleteAnnonce(this.annonce.id).subscribe(() => {
        this.loadingDeleteAnnonce = false;
        this.deletedAnnonce.emit(this.annonce.id);
      });
    }
  }

  setupLanguageChangeCallback(){
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
      console.log('language', this.lang);
    }); 
  }
}
