<div class="row">
    <div class="col">
        {{ breakingNew.nom }}
    </div>
    <div class="ml-auto">
        <button type="button" color="primary" class="mr-2" mat-raised-button (click)="triggerModal(updateEquipeModal)">{{ 'modifier' | translate }}</button>
        <button type="button" color="warn" mat-raised-button (click)="triggerModal(confirmation_delete)">{{ 'supprimer' | translate }}</button>
    </div>
</div>

<ng-template let-modal #updateEquipeModal>
    <div class="modal-content">
        <div class="modal-header">
            Edition du breaking news
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label>Nom du breaking new:</label>
                    <input type="text" class="form-control" name="nom" [(ngModel)]="newbreakingNew.nom">
                  </div>
                  <div class="form-group">
                    <label>Description:</label>
                    <textarea spellcheck="false" class="form-control" rows=5 name="description" [(ngModel)]="newbreakingNew.description"></textarea>
                  </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" color="warn" mat-raised-button (click)="modal.close()">Annuler</button>
            <button type="button" color="primary" mat-raised-button (click)="edit()">
                <span class="spinner-border spinner-border-sm" *ngIf="loadingEdit"></span>Modifier
            </button>
        </div>
    </div>

</ng-template>

<ng-template let-modal #confirmation_delete>
    <div class="modal-header">Confirmation suppression</div>
    <div class="modal-body">Etes-vous sur de vouloir supprimer ce breaking new</div>
    <div class="modal-footer">
        <button type="button" color="warn" mat-raised-button (click)="modal.close()">Annuler</button>
        <button type="button" color="primary" mat-raised-button (click)="delete()">
            <span class="spinner-border spinner-border-sm" *ngIf="loadingDelete"></span>Supprimer
        </button>
    </div>
</ng-template>
