import { Router } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { User, UserAdapter } from '@models/user';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { CustomDatasource } from '@datasources/custom-datasource';
import { Student, StudentAdapter } from '@models/student';
import { UserService } from '@services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { tap, switchMap, debounceTime, distinctUntilChanged, delay } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { merge, fromEvent } from 'rxjs';
import { _supportsShadowDom } from '@angular/cdk/platform';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthenticationService } from '@services/authentication.service';
import { MediaObjectService } from '@services/media-object.service';
import { PaginationService } from '@services/data-transfer/pagination.service';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {
  checked = false;
  loading:boolean = false;
  currentId: number = 0;
  errorForbidden: boolean = false;

  displayedColumns = ['firstname', 'lastname', 'username', 'action'];
  dataSource: CustomDatasource<User>;
  closeModal: string;

  nbUsers = 0;
  pageSize: number = 10;
  searchControl = new FormControl('');
  filters = {};

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private userAdapter: UserAdapter,
    private mediaObjectService: MediaObjectService,
    private paginationService: PaginationService,
    private router: Router
    ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  ngOnInit(): void {
    this.dataSource = new CustomDatasource<User>(this.userService, this.userAdapter);
    this.listenChangesSearchControl();
    this.dataSource.load();
  }

  ngAfterViewInit(): void {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      debounceTime(500),
      tap(() => {
        this.paginationService.setPageSize(this.paginator.pageSize);
        this.load();
      })
    ).subscribe();

    this.paginationService.pageSize$.subscribe(pageSize => {
      this.pageSize = pageSize;
      this.paginator.pageSize = pageSize;
      this.load();
    });
  }

  load(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};

    this.dataSource.load(this.filters, this.paginator.pageIndex, this.paginator.pageSize, orders);
  }

  toggleActive(event: MatSlideToggleChange, currentStatus: boolean, id:number){
    this.currentId = id;
    this.loading = true;

    this.userService.toggleActive(!currentStatus, id).subscribe((user) => {

      this.dataSource.loading$.subscribe((isloading) => {
        this.loading = isloading;
      })
    },
    (error) => {
      this.errorForbidden = true;
      this.dataSource.loading$.subscribe((isloading) => {
        this.loading = isloading;
      })
    },() => {
      this.load();
    });
  }
  // onConfirm(usr: User){
  //   if(confirm("Are you sure to delete "+ usr.username)) {
  //     this.userService.deleteUser(usr.id).subscribe();
  //   }
  // }

  isAdmin() : boolean{
    return this.authService.isAdmin();
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onDelete(id: number){
    this.userService.deleteUser(id).subscribe(
      () => this.dataSource.load()
    );
  }

  contentUrl(photo){
    return this.mediaObjectService.contentUrl(photo);
  }

  goToCreateUser(){
    this.router.navigate(['/users/create']);
  }

  listenChangesSearchControl(){
    this.searchControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(searchValue => {
      if(searchValue != ""){
        this.filters = {
          'firstname': searchValue
        }

        this.paginator.firstPage();
      }else{
        this.filters = {};
      }
      this.load();
    });
  }
}
