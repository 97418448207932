import { ifStmt, isNull } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnChanges {

  // @Input() departTime?: Date;
  @Input() seconds?: number;
  @Input() paused: boolean;

  @Output('endedTime') endTime = new EventEmitter<Date>();

  timerSubscription: Subscription;

  timerDisplay: any = {
    hours: 0,
    minutes: { digit1: 0, digit2: 0 },
    seconds: { digit1: 0, digit2: 0 },
  };


  time = 0;
  currentDate: Date = new Date();
  temp;

  timePassed = 0;

  constructor() { }

  ngOnInit(): void {
    this.playTimer();

    setTimeout(() => {

      if(this.paused){
        this.timerSubscription.unsubscribe();
      }
    }, 0);
  }

  ngOnChanges(): void{
    if(isNaN(this.seconds)){
      this.timePassed = 0;
    }

    console.log('timer changed');
    this.playTimer();

    if(this.paused){
      this.timerSubscription.unsubscribe();
    }else{
      let seconds = this.seconds - this.timePassed;
      this.playTimer(seconds);
    }
  }

  get noChrono(){
    return isNaN(this.seconds);
  }

  playTimer(seconds: any = null){
    if(seconds != null){
      this.time = seconds;
    }else{
      this.time = this.seconds;
    }
    console.log('play timer for ', this.time);
    if (this.time >= 0 && (!this.timerSubscription || this.timerSubscription.closed)) {
      this.timerSubscription = timer(0, 1000).subscribe(ellapsedCycles => {
        this.time--;
        this.timePassed++;

        if (this.time >= 0) {
          this.timerDisplay = this.getDisplayTimer(this.time)

        }
        else {
          this.endTime.emit(new Date());
          this.timerSubscription.unsubscribe();
        }
      });
    }
  }

  getDisplayTimer(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = '0' + Math.floor(time % 3600 / 60);
    const seconds = '0' + Math.floor(time % 3600 % 60);
    return {
      hours: hours,
      minutes: { digit1: minutes.slice(-2, -1), digit2: minutes.slice(-1) },
      seconds: { digit1: seconds.slice(-2, -1), digit2: seconds.slice(-1) },
    };
  }

  calculateDiff(dateSent: Date) {
    let currentDate = new Date();

    dateSent = new Date(dateSent);



    var seconds = (dateSent.getTime() - currentDate.getTime()) / 1000;
    return Math.floor(seconds);

  }

}
