import { JeuService } from '@services/jeu.service';
import { popBreakingNewAction } from '@app/core/actions/app.actions';
import { BreakingNew } from '@models/breakingNew';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { Historique } from '@models/historique';

@Component({
  selector: 'app-journal-liste-item',
  templateUrl: './journal-liste-item.component.html',
  styleUrls: ['./journal-liste-item.component.scss']
})
export class JournalListeItemComponent implements OnInit {

  @Input() historique: Historique;
  @Input() isAnimateur: boolean;

  isBlinking: boolean = false;

  constructor(
    private store: Store,
    private jeuService: JeuService
  ) { }

  ngOnInit(): void {
    this.initBlinkingTime();
  }

  popBreakingNew(){
    let breakingNew = this.historique;
    breakingNew['nom'] = breakingNew['titre'];
    breakingNew['createur'] = this.historique.messageData?.createur;

    this.jeuService.popBreakingNew(breakingNew, this.historique.jeu.id).subscribe(() => {
      this.historique['isCommunicated'] = true;
    });
  }

  initBlinkingTime(){
    var millisecondsToBlink = 10000;

    if(this.historique['isNew']){
      this.isBlinking = true;
      setTimeout(() => {
        this.isBlinking = false;
      }, millisecondsToBlink);
    }
  }
}
