<ng-container *ngIf="phase && phase.started">
  <div class="game-time">
    <!--<span class="material-icons-outlined"> timer </span>-->
    <ng-container *ngIf="withPause">
      <span *ngIf="!phase.paused" class="play-pause" (click)="pausePhase()">
        <mat-icon *ngIf="!loadingPausePhase">pause_presentation</mat-icon>
        <span class="spinner-border spinner-border-sm" *ngIf="loadingPausePhase"></span>
      </span>
      <span *ngIf="phase.paused" class="play-pause" (click)="playPhase()">
        <mat-icon *ngIf="!loadingPlayPhase">play_arrow</mat-icon>
        <span class="spinner-border spinner-border-sm" *ngIf="loadingPlayPhase"></span>
      </span>
    </ng-container>
    <app-timer #timer [seconds]="timerSeconds" (endedTime)="onPhaseFinished()" [paused]="paused"></app-timer>
  </div>
</ng-container>
