import { TypeProduitService } from '@services/type-produit.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CustomDatasource } from '@datasources/custom-datasource';
import { Produit, ProduitAdapter } from '@models/produit'
import { TypeProduit } from '@models/typeProduit';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaObjectService } from '@services/media-object.service';
import { ProduitService } from "@services/produit.service";
import { merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-produit-list',
  templateUrl: './produit-list.component.html',
  styleUrls: ['./produit-list.component.scss']
})
export class ProduitListComponent implements OnInit, AfterViewInit {

  dataSource : CustomDatasource<Produit>;
  displayedColumns = ["nom", "action"];
  defaultPageSize = 5;
  selectedFilter: string;

  private closeModal: string;
  public types: TypeProduit[];
  selectedType: string = 'all';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort : MatSort;

  constructor(
    private produitService : ProduitService,
    private mediaObjectService : MediaObjectService,
    private typeProduitService: TypeProduitService,
    private adapter : ProduitAdapter,
    private modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).pipe(
      debounceTime(500),
      tap(() => {
        if(this.selectedFilter == this.types[0].iri){
          this.load1();
        }else if(this.selectedFilter == this.types[1].iri){
          this.load2();
        }else{
          this.load();
        }
      })
    ).subscribe();
  }

  load(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};

    this.selectedFilter = null;
    this.selectedType = 'all';

    this.dataSource.load({"exists[archived]": false}, this.paginator.pageIndex, this.paginator.pageSize, orders);
  }

  load2(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};
    this.dataSource.load({"type.slug": 'investissement-innovation', "exists[archived]": false}, this.paginator.pageIndex, this.paginator.pageSize, orders);
  }

  load1(){
    let sortCol = this.sort.active;
    let sortDirection = this.sort.direction;
    let orders = {[sortCol]: sortDirection};
    this.dataSource.load({"type.slug":'investissement-securite', "exists[archived]": false}, this.paginator.pageIndex, this.paginator.pageSize, orders);
  }



  ngOnInit(): void {

    this.typeProduitService.getAll().subscribe(
      (alltypes)=> {
        this.types = alltypes;
        const type3 = {
          iri: 'all',
          id: 5,
          nom: 'Tous les types',
          slug: ' ',
        }

        this.types.push(type3);

        this.selectedFilter = this.types[2].iri;
      }
    );


    this.dataSource = new CustomDatasource<Produit>(this.produitService, this.adapter);
    this.dataSource.load({"exists[archived]": false}, null, this.defaultPageSize);
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onDelete(id: number){
    this.produitService.delete(id).subscribe(
      () => {
        if(this.selectedFilter == this.types[0].iri){
          this.load1();
        }
        else if(this.selectedFilter == this.types[1].iri){
          this.load2();
        }else{
          this.load();
        }
      }
    )
  }

  contentUrl(logo){
    return this.mediaObjectService.contentUrl(logo);
  }

  onChangeType(event){
    let selected = event.target.value;
    this.selectedFilter = selected;
    if(selected==this.types[0].iri){
      this.load1();
    }
    else if(selected==this.types[1].iri){
      this.load2();
    }
    else{
      this.load();
    }
  }

}
