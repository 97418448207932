import { BreakingNew } from '@models/breakingNew';
import { Jeu } from '@models/jeu';
import { Joueur } from '@models/joueur';
import { Historique } from '@models/historique';
import { Budget } from '@models/budget';
import { DebriefElement } from '@models/debrief-element';
import { Room } from '@models/room';

export interface AppState{
  jeu?: Jeu | null,
  joueur?: Joueur | null,
  historiques?: Historique[] | null,
  budget?: Budget | null,
  loadResultat?: number,
  loadSondage?: number,
  loadDecisions?: number,
  startPitch?: number,
  loadVote?: number,
  changementVote?: number,
  changementNouveauxProduits?: number,
  virement?: number,
  etapeSuivante?: number,
  debriefElements?: DebriefElement[] | null,
  unseenRooms?: number[],
  changementCoequipierSondage?: number,
  breakingNew?: BreakingNew | null
}

export const initialState: AppState = {
  jeu: null,
  joueur: null,
  historiques: [],
  budget: null,
  loadResultat: 0,
  loadSondage: 0,
  loadDecisions: 0,
  startPitch: 0,
  loadVote: 0,
  changementVote: 0,
  changementNouveauxProduits: 0,
  virement: 0,
  etapeSuivante: 0,
  debriefElements: [],
  unseenRooms: [],
  changementCoequipierSondage: 0,
  breakingNew: null
}
