<html lang="en">
<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">
  <meta content="" name="description">
  <meta content="" name="author">
  <title>CSG | login</title>

</head>

<body>
  <div class="video-bg">
    <video src="assets/images/bg-body.mp4" loop muted autoplay></video>
    <div class="overlay"></div>
    <div class="container-custom login-back">
      <div class="login-form mt-5">
        <div class="logo-gm"><img src="assets/images/logo-blanc.png"></div>
        <div class="formulaire">
          <div class="titre-data">
            <h2><span class="light">Identifiez-</span>vous !</h2>
          </div>
          <div class="csg-form">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <h3 class="error" *ngIf="errorLogin"><i class="danger"></i><em>Login ou mot de passe incorrect!</em></h3>
              <h3 class="warning" *ngIf="errorAccount"><em>Votre compte n'est pas encore activé. Veuillez contacter l'administrateur, vous recevrez un mail lors de l'activation</em></h3>
              <h3 *ngIf="signupSuccess">Votre inscription est reçue. Veuillez attendre la validation de l'administrateur</h3>
              <div class="form-label-group-custom">
                <input type="email" value="" name="email" id="inputEmail" class="form-control-custom" placeholder="Utilisateur" required="" autofocus="" formControlName="username">
                <i class="icon-email"></i>
                <label for="inputEmail">Email / Utilisateur</label>
              </div>
              <div class="form-label-group-custom">
                <input type="password" name="password" id="inputPassword" class="form-control-custom" placeholder="Mot de passe" required="" autofocus="" formControlName="password">
                <i class="icon-password"></i>
                <label for="inputPassword">Mot de passe</label>
              </div>
              <button type="submit" class="btn_gradient">
                <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>
                SE CONNECTER
              </button>
              <span (click)="resetPassword()" class="reset-password">
                <ng-container *ngIf="!loadingResetPassword">Mot de passe oublié ?</ng-container>
                <span class="spinner-border spinner-border-sm" *ngIf="loadingResetPassword"></span>
              </span>
              <span>{{ message }}</span>
            </form>
            <div class="titre-data m-4 text-center">
              <h2><span class="light"></span>ou</h2>
            </div>
            <button type="button" (click)="goTo('/signup')" routerLink="/signup" class="btn_gradient mt-0">
              S'INSCRIRE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
</html>
