import { createReducer, Action, on } from '@ngrx/store';
import * as JeuActions from '../actions/jeu.actions';
import { state } from '@angular/animations';
import {initialState, JeuState} from '../states/jeu.state';

const jeuReducer = createReducer(
  initialState,
  on(JeuActions.resetJeuAction, (state: JeuState, action: Action) => ({id: 0, tour: 0, started: false})),
  on(JeuActions.jeuSelectAction, (state: JeuState, action: Action) => ({...state, id: action['id']})),
  on(JeuActions.playJeuAction, onPlayJeuAction),
  on(JeuActions.nouvelleAnalyseMarcheAction, onNouvelleAnalyseMarche),
  on(JeuActions.reponseSondageAction, onReponseSondageAction)

);

function onPlayJeuAction(state: JeuState, action: Action){
  console.log('play jeu action reducer');
  sessionStorage.setItem('jeuState', JSON.stringify(state));

  return ({...state, id: action['id']});
}

export function reducer(state: JeuState | undefined, action: Action) {
  return jeuReducer(state, action);
}

function onNouvelleAnalyseMarche(state: JeuState, action: Action){
  
  return {...state, nouvelleAnalyseMarche: state['nouvelleAnalyseMarche'] + 1 }
}

function onReponseSondageAction(state: JeuState, action: Action){
  return {...state, reponseSondage: state['reponseSondage'] + 1}
}
