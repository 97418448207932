import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { HistoriqueService } from '@services/historique.service';
import { Historique } from '@models/historique';
import { Jeu } from '@models/jeu';
import { Store, select } from '@ngrx/store';
import { initAppHistoriquesAction } from '@app/core/actions/app.actions';
import { selectAppHistoriques } from '@app/core/selectors/app.selector';

@Component({
  selector: 'app-journal-liste',
  templateUrl: './journal-liste.component.html',
  styleUrls: ['./journal-liste.component.scss']
})
export class JournalListeComponent implements OnInit, OnChanges {
  @Input() jeu: Jeu;
  @Input() isAnimateur: boolean;

  @ViewChild('journalContent') journalContent;

  historiques: Historique[];

  constructor(
    private historiqueService: HistoriqueService,
    private store: Store
  ) { }

  ngOnChanges(): void{
    console.log('changed journal liste')
    if(this.jeu){
      this.getJournal();
    }
  }

  ngOnInit(): void {
    if(this.jeu){
      this.getJournal();
    }

    this.onInitHistoriques();
  }

  onInitHistoriques(){
    this.store.pipe(select(selectAppHistoriques)).subscribe(historiques => {
      this.historiques = historiques;
      console.log('init historiques', historiques);

      // Scroll to bottom
      if(this.journalContent){
        // set timeout because angular has not displayed the new message yet, we let one tick
        setTimeout(() => {
          // this.journalContent.nativeElement.scrollTop = this.journalContent.nativeElement.scrollHeight;
          this.journalContent.nativeElement.scrollTop = -this.journalContent.nativeElement.scrollHeight;
        }, 0);
      }

      
    });
  }

  getJournal(){
    this.historiqueService.getPublished(this.jeu.id).subscribe(historiques => {
      // this.historiques = historiques;
      // console.log('histo', this.historiques);
      this.store.dispatch(initAppHistoriquesAction({ historiques: historiques}));
    });
  }
}
