
<div *ngIf="equipe" class="division-data">
  <div class="division-left">
    <div class="user-profil">
      <div class="user-photo" [formGroup]="equipeForm">
        <img [src]="contentUrl(equipe.logo)" alt="logo" (click)="fileInput.click()">
        <input class="form-control" type="file" id="logo_type_equipe" name="logo" (change)="onFileChange($event)" style="opacity: 0;" #fileInput formControlName="logo">
      </div>
    </div>
  </div>

  <div class="division-right">

    <form (ngSubmit)="updateEquipe()" [formGroup]="equipeForm">
      <div class="row">
        <div class="col-12">
          <h2>{{ 'equipe' | translate }} {{ equipe.nom }}</h2>

          <div class="form-group">
            <label>{{ 'company_name' | translate }}</label>
            <input type="text" class="form-control" formControlName="nom">
          </div>

          <div class="form-group">
            <label>Description <ng-container *ngIf="equipe.type.ecosysteme == 'etat'  || equipe.type.ecosysteme == 'entreprise'">/ {{ 'mission_statement' | translate }}</ng-container></label>
            <textarea spellcheck="false" class="form-control" formControlName="description"></textarea>
          </div>

          <ng-container #special>
            <ng-container *ngIf="equipe.type.ecosysteme == 'etat'">
              <div class="form-group">
                <label>Budget {{ 'en_million_euros' | translate }}</label>
                <input type="number" class="form-control" formControlName="budget"/>
              </div>
            </ng-container>
            <ng-container *ngIf="equipe.type.ecosysteme == 'menace'">
              <div class="form-group">
                <label>{{ 'budget_depart' | translate }} {{ 'en_million_euros' | translate }}</label>
                <input type="number" class="form-control" formControlName="budget"/>
              </div>
            </ng-container>
            <ng-container *ngIf="equipe.type.ecosysteme == 'entreprise'">
              <div class="form-group">
                <label>{{ 'company_size' | translate }}</label>
                <input type="number" class="form-control" id="rapportForce" formControlName="rapportForce" [class.is-invalid]="equipeForm.controls.rapportForce?.invalid"/>
                <div class="invalid-feedback" *ngIf="equipeForm.controls.rapportForce?.invalid">{{ 'enter_number_0_100' | translate }}</div>
              </div>
              <div class="form-group">
                <label>{{ 'average_revenue_per_client' | translate }}</label>
                <input type="number" class="form-control" formControlName="ratioClient"/>
              </div>
            </ng-container>
          </ng-container>

          <div class="form-group">
              <button mat-raised-button color="primary" [disabled]="equipeForm.invalid || loading"><span class="spinner-border spinner-border-sm" *ngIf="loading"></span>
                {{ 'valider' | translate }}
              </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #listUserModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Création d'un joueur</h4>
      <button type="button" class="close" aria-label="Fermer" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div *ngIf="updateJoueur">L'utilisateur assigné à ce joueur est: {{currentJoueur.user.username}}</div>
        <div class="form-group">
          <label>Changer de Joueur réel:</label>
          <mat-select [(ngModel)]="currentJoueur.iriUser" name="iri_user" class="dark">
            <mat-option *ngFor="let user of notSelectedUser(allUsers)" [value]="user.iri">{{ user.username }}</mat-option>
          </mat-select>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">{{ 'fermer' | translate }}</button>
      <button type="button" *ngIf="!updateJoueur" mat-raised-button color="primary" (click)="addUser()">Sélectionner</button>
      <button type="button" *ngIf="updateJoueur" mat-raised-button color="primary" (click)="modifyUser()">Modifier</button>
    </div>
  </div>
</ng-template>
