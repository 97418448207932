<div [class.journal-declaration]="historique.type == 'declaration-officielle'" [class.journal-hashtag]="historique.type == 'new-hashtag'" [class.journal-breaking-new]="historique.type == 'breaking-new'" [class.new-journal-item]="isBlinking" class="journal-item" [class.journal-reponse-sondage]="historique.type == 'reponse-sondage'">
    <div class="journal-content">
      <div class="journal-author">
        <span *ngIf="historique.type == 'declaration-officielle'">{{ 'journal_declaration' | translate }}
          <span *ngIf="historique.messageData.communicationPublic? historique.messageData.communicationPublic : false">({{ 'journal_avec_espace_public' | translate }}) </span>

        </span>
        <span *ngIf="historique.type == 'new-hashtag'">{{ 'journal_nouvel_hashtag' | translate }}</span>
        <span *ngIf="historique.type == 'breaking-new'">Breaking News</span>
        <ng-container *ngIf="historique.messageData && !(historique.messageData.ecosysteme == 'client')">{{ historique.messageData.createur }}</ng-container>
        <ng-container *ngIf="historique.messageData && (historique.messageData.ecosysteme == 'client')">{{ historique.messageData.typeJoueurCreateur }}</ng-container>
      </div>


      <!-- Content -->

      <ng-container *ngIf="historique.titre && historique.titre != ''">{{ historique.titre }}:</ng-container> {{ historique.description }}


      <!-- End content -->

      <!-- Begin of special button -->
      <ng-container *ngIf="historique.type == 'declaration-officielle' && (historique.messageData.communicationPublic? historique.messageData.communicationPublic : false) && isAnimateur">
        <button class="btn btn-primary btn-xs btn-declarer" (click)="popBreakingNew()" *ngIf="!historique.isCommunicated">{{ 'declarer' | translate }}</button>
        <button class="btn btn-primary btn-xs btn-declarer already-communicated" (click)="popBreakingNew()" *ngIf="historique.isCommunicated">{{ 'declarer' | translate }}</button>
      </ng-container>
      <!-- End of special button -->

      <div class="journal-time">
        {{ historique.createdAt | date: 'HH:mm'}}
      </div>
    </div>
  </div>
