import { ChronometreComponent } from './shared/components/chronometre/chronometre.component';
import { MessagerieModule } from './messagerie/messagerie.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RessourceModule } from './ressource/ressource.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StudentComponent } from './student/student.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { UserModule } from './user/user.module';
import { TypeEquipeModule } from "./type-equipe/type-equipe.module";
import { DatePipe } from '@angular/common';

// PrimeNG modules
import {MenubarModule} from 'primeng/menubar';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {PanelMenuModule} from 'primeng/panelmenu';

import { JwtInterceptor } from '@interceptors/jwt.interceptor';
import { ErrorInterceptor } from '@interceptors/error.interceptor';
import { TimeoutInterceptor } from '@interceptors/timeout.interceptor';
import { appInitializer } from '@initializers/app-refresh-initializer';
import { AuthenticationService } from '@services/authentication.service';
import { AppService } from '@services/app.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SignupComponent } from './signup/signup.component';

import {MatSidenavModule} from '@angular/material/sidenav';
import { TypeJeuModule } from './type-jeu/type-jeu.module';
import { JeuModule } from './jeu/jeu.module';
import { TypeJoueurModule } from './type-joueur/type-joueur.module';

import { ProduitModule } from './produit/produit.module';
import { AnimationsComponent } from './pages/animations/animations.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from '../app/core/reducers';

import * as fromJeu from '@app/core/reducers/jeu.reducer';
import * as fromMessage from '@app/core/reducers/message.reducer';
import * as fromUser from '@app/core/reducers/user.reducer';
import * as fromApp from '@app/core/reducers/app.reducer';

import { RessourceRoutingModule } from './ressource/ressource-routing.module';
import { DiscussionComponent } from './shared/discussion/discussion.component';
import { JeuAnimationComponent } from './pages/jeu-animation/jeu-animation.component';
import { EffectsModule } from '@ngrx/effects';
import { JeuEffects } from '@app/core/effects/jeu.effect';
import { BreakingNewComponent } from './pages/jeu-animation/breaking-new/breaking-new.component';
import { SalonNouveauteComponent } from './pages/jeu-animation/salon-nouveaute/salon-nouveaute.component';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatList, MatListModule } from '@angular/material/list';
import { EquipeListComponent } from './pages/jeu-animation/equipe/equipe-list/equipe-list.component';
import { EquipeShowComponent } from './pages/jeu-animation/equipe/equipe-show/equipe-show.component';
import { EquipeEditComponent } from './pages/jeu-animation/equipe/equipe-edit/equipe-edit.component';
import { JoueurListComponent } from './pages/jeu-animation/joueur/joueur-list/joueur-list.component';
import { JoueurShowComponent } from './pages/jeu-animation/joueur/joueur-show/joueur-show.component';
import { JoueurEditComponent } from './pages/jeu-animation/joueur/joueur-edit/joueur-edit.component';
import { MatSelectModule } from '@angular/material/select';
import { TimelinePageComponent } from './pages/timeline-page/timeline-page.component';
import { ActionJeuComponent } from './pages/timeline-page/action-jeu/action-jeu.component';

import { SharedModule } from '@app/shared/shared/shared.module';
import { DashboardAnimateurComponent } from './pages/animateur/dashboard-animateur/dashboard-animateur.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { JoueurListItemComponent } from './pages/jeu-animation/joueur/joueur-list/joueur-list-item/joueur-list-item.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToastModule } from 'primeng/toast';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    StudentComponent,
    LoginComponent,
    DashboardComponent,
    SignupComponent,
    AnimationsComponent,
    DiscussionComponent,
    JeuAnimationComponent,
    BreakingNewComponent,
    SalonNouveauteComponent,
    EquipeListComponent,
    EquipeShowComponent,
    EquipeEditComponent,
    JoueurListComponent,
    JoueurShowComponent,
    JoueurEditComponent,
    ChronometreComponent,
    TimelinePageComponent,
    ActionJeuComponent,
    DashboardAnimateurComponent,
    JoueurListItemComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    NgbModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    UserModule,
    MessagerieModule,
    RessourceModule,
    TypeEquipeModule,
    ModalModule.forRoot(),
    MatSidenavModule,
    AccordionModule,
    MatListModule,
    MultiSelectModule,
    MatSelectModule,


    InputTextModule,
    MenubarModule,
    TabViewModule,
    PanelMenuModule,
    TypeJeuModule,
    JeuModule,
    TypeJoueurModule,
    ProduitModule,
    StoreModule.forRoot({
      app: fromApp.reducer,
      jeu: fromJeu.reducer,
      message: fromMessage.reducer,
      user: fromUser.reducer
    }),
    RessourceRoutingModule,
    EffectsModule.forRoot([JeuEffects]),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage: 'fr',
    }),
    ToastModule
  ],
  providers: [
    DatePipe,
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true},
    { provide: appInitializer, useFactory: appInitializer, multi: true, deps: [AuthenticationService] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
