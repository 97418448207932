import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';
import { MediaObjectService } from './../../services/media-object.service';
import { UserService } from './../../services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { matchPasswordValidator } from '@app/validators/signup-validator';
import { map, switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  verifyUsernameValidator = (userService: UserService, time: number=500) => {
    return (input: FormControl)=>{
      return timer(time).pipe(
        switchMap(()=>userService.findUsername(input.value)),
        map(res => {
          return res.length ? {usernameExist: true}: null
        })
      );
    }
  };

  imageUrl: any = "";
  loading: boolean = false;

  userForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    username: new FormControl(null, Validators.required, this.verifyUsernameValidator(this.userService)),
    password: new FormControl('', Validators.required),
    confirm_password: new FormControl('', Validators.required),
    phone: new FormControl(""),
    organisation: new FormControl(""),
    description: new FormControl(""),
    photo_source: new FormControl("")
  },
  {
    validators: matchPasswordValidator
  });

  isSubmitting: boolean = false;

  constructor(
    private userService: UserService,
    private mediaObjectService: MediaObjectService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.imageUrl = this.mediaObjectService.contentUrl({});
  }

  ngOnInit(): void {
  }

  isUsernameToken():boolean{
    return this.userForm.get('username').hasError('usernameExist');
  }

  onSelectImage(e){
    if(e.target.files.length>0){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      }
      console.log(e.target.files[0]);
      this.userForm.get('photo_source').setValue(e.target.files[0]);
    }
  }

  onSubmit(){
    if(this.userForm.valid){
      const formValue = this.userForm.value;
      this.loading = true;
      if(formValue["photo_source"] == ""){
        const newUser =  {
          'lastname': formValue['lastname'],
          'firstname': formValue['firstname'],
          'username': formValue['username'],
          'password': formValue['password'],
          'phone' : formValue['phone'],
          'email': formValue['username'],
          'organisation' : formValue['organisation'],
          'description' : formValue['description'],
          'active': false,
        };
        this.authenticationService.signup(newUser).subscribe(
          (data)=>{
            this.goToUserList();
          },
          (error)=>{
          }
        );
      }
      else {
        var formData = new FormData();
        console.log(this.userForm.get('photo_source').value);
        formData.append("file", this.userForm.get('photo_source').value);

        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res) => {
            console.log(res);

            const newUser =  {
              'lastname': formValue['lastname'],
              'firstname': formValue['firstname'],
              'username': formValue['username'],
              'password': formValue['password'],
              'phone': formValue['phone'],
              'email': formValue['username'],
              'organisation': formValue['organisation'],
              'description': formValue['description'],
              'photo' : res["@id"],
              'active': false,
            };
            console.log(newUser);
            this.authenticationService.signup(newUser).subscribe(
              (data)=>{
                this.goToUserList();
              },
              (error)=>{

              }
            );
          }
        )
      }

    }
  }

  goToLogin(){
    this.router.navigate(['/login'], {
      state: {
        signupSuccess: true
      }
    });
  }

  goToUserList(){
    this.router.navigate(['/users']);
  }
}
