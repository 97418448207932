<h2>4. {{ 'produits_innovation' | translate }}</h2>

<ng-container *ngIf="jeu">

  <app-edit-produits-salon [jeu]="jeu" [tour]="0" *ngIf="jeu.isWarmup"></app-edit-produits-salon>
  <app-edit-produits-salon *ngFor="let t of [].constructor(jeu.nbTours); let i=index;" [jeu]="jeu" [tour]="i+1">

  </app-edit-produits-salon>

</ng-container>

<div class="float-button">
<button mat-raised-button color="primary" (click)="suivant()">
  {{ 'suivant' | translate }}
</button>
</div>
