<mat-list-item>
  <div class="container">

    <div class="row">
      <div class="col-3 description-joueur">
        <a [routerLink]="goTo(joueur.id)"><span> {{ joueur.equipe.nom }}, </span> {{ joueur.type['traductionKey'] | translate }} </a>
      </div>
      <div class="col-9">
        <div class="row">


          <div class="col-6">

            <mat-form-field>
              <mat-label>{{ 'joueur_reel' | translate }}</mat-label>
            <mat-select [formControl]="joueurReel" (selectionChange)="assignJoueur()" [disabled]="loadingAssignJoueur || !canEdit">
              <mat-option [value]=""></mat-option>
              <mat-option [value]="joueurReelTemplate.id" *ngFor="let joueurReelTemplate of allFreeUsers; let i=index" [disabled]="assignedUsers.indexOf(joueurReelTemplate.id) != -1">{{ joueurReelTemplate.username }}</mat-option>
            </mat-select>
            </mat-form-field>
            <span class="spinner-border spinner-border-sm" *ngIf="loadingAssignJoueur"></span>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-10">
                <input type="email" class="form-control" placeholder="{{ 'email_joueur' | translate }}" [formControl]="emailSending"/>
                <label>Email (pour envoi)</label>
              </div>
              <div class="col-2">
                <button color="primary" mat-raised-button (click)="sendMailToJoueur()" [disabled]="loadingSendMail">
                  <span class="spinner-border spinner-border-sm" *ngIf="loadingSendMail"></span>
                  <mat-icon>send</mat-icon>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</mat-list-item>
<hr>
