import { Component, ViewRef, ViewChild, ElementRef, TemplateRef, ViewChildren, AfterViewInit, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Title, Meta } from '@angular/platform-browser';
import { AuthenticationService } from '@services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as io from 'socket.io-client';

import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { addMessageAction } from '@app/core/actions/message.actions';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'CSG';
  noConnectionModalRef: BsModalRef;
  @ViewChild('noConnectionModal') noConnectionModal: TemplateRef<any>;

  private socket;
  private data;

  constructor(
    private appService: AppService,
    private modalService: BsModalService,
    private titleService: Title,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private translate: TranslateService,
    private location: Location,
    private meta: Meta
  ){
      this.socket = io.io(environment.socketIoUrl);


    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');

    // Setting meta attributes
    this.addMetaTags();

    // Title
    this.setTitle("CSG");
  }

  ngOnInit(){

    this.setTitle("CSG");

    if(! this.authService.currentUserValue){
      if(this.location.path().split("?")[0] != '/reset-password'){
        this.router.navigate(['/login']);
      }
    }

  }

  ngAfterViewInit()
  {
    this.appService.connectionTimeout.subscribe(connectedToServer => {
      if(!connectedToServer)
      {
        this.noConnectionModalRef = this.modalService.show(this.noConnectionModal);
      }
    });
  }

  setTitle(newTitle: string){
    this.titleService.setTitle(newTitle);
  }

  reessayer(){
    window.location.reload();
  }

  addMetaTags(){
    this.meta.addTags([
      { name: 'description', content: "Seriousgame de cybersécurité, simulation sur le fonctionnement de l'entreprise, l'Etat, les clients et le hacker"}
    ]);
    console.log('meta tags added');
  }
}
