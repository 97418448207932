<div>
  <mat-table class="lessons-table" matSort matSortActive="id,debut" matSortDirection="asc" matSortDisableClear [dataSource]="dataSourceT">
      <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
          <mat-cell *matCellDef="let jeu">
              {{ jeu.id }}
          </mat-cell>
      </ng-container>
      <ng-container matColumnDef="finished">
        <mat-header-cell *matHeaderCellDef>status</mat-header-cell>
        <mat-cell *matCellDef="let jeu">{{ jeu.finished }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="date_debut">
        <mat-header-cell *matHeaderCellDef mat-sort-header="debut">Date debut</mat-header-cell>
        <mat-cell *matCellDef="let jeu">{{ jeu.debut | date:'d/M/yy, h:mm a' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="nom_jeu">
          <mat-header-cell *matHeaderCellDef>{{ 'nom' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let jeu">{{ jeu.nom }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description_jeu">
        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell *matCellDef="let jeu">{{ jeu.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="nbJoueurs_jeu">
        <mat-header-cell *matHeaderCellDef>Nombre de joueurs</mat-header-cell>
        <mat-cell *matCellDef="let jeu">{{ jeu.nbJoueurs }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let jeu">
              <!-- <button mat-icon-button color="warning" [routerLink]="['/jeux/edit', jeu.id]">
                  <mat-icon>build</mat-icon>
              </button> -->
              <button mat-icon-button color="primary" (click)="goToAnimerJeu(jeu.id)" *ngIf="!isAnimation">
                <mat-icon>videogame_asset</mat-icon>
              </button>
              <button mat-icon-button color="primary" [routerLink]="['/jeux/designer/creation', jeu.id]" *ngIf="(!isEnCours || !isAnimation) && !isFinished">
                <mat-icon>build</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="onSelectJeu(jeu.id)" *ngIf="isAnimation && !isFinished">
                <mat-icon>exit_to_app</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="replayGame(jeu.id)" [disabled]="loadingReplay == jeu.id" *ngIf="isFinished">
                <span class="spinner-border spinner-border-sm" *ngIf="loadingReplay == jeu.id"></span>
                <mat-icon *ngIf="loadingReplay != jeu.id">play_arrow</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="triggerModal(modalData)" *ngIf="!isAnimation || isFinished">
                <mat-icon>close</mat-icon>
              </button>

              <ng-template #modalData let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Delete </h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p><strong>{{ 'confirmation_supprimer' | translate}} <span class="text-primary">"{{jeu.nom}}"</span></strong></p>
                </div>
                <div class="modal-footer">
                  <button type="button" mat-raised-button color="warn" (click)="modal.close('Save click')">Fermer</button>
                  <button type="button" mat-raised-button color="primary" (click)="onDelete(jeu.id,false); modal.close('Save click')">{{ 'supprimer' | translate }}</button>
                </div>
              </ng-template>

            </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row;columns : displayedColumns"></mat-row>

  </mat-table>
    <mat-paginator [length]="dataSourceT.total" [pageSize]="defaultPageSize" [pageSizeOptions]="[5,10,20]"></mat-paginator>
    <div class="row" *ngIf="isAdmin">
      <div class="col text-center">
        <a [routerLink]="['/jeux/designer/creation']" mat-raised-button color="primary">{{ 'creer_nouveau_jeu' | translate }}</a>
      </div>
    </div>

  </div>
