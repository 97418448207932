import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * data is in the format 
   * {
   *    'emails': [idJoueur, emailToSend], 
   *    'jeu': <id_jeu>
   * }
   */
  sendMailJoueurs(data: any){
    const url = `${environment.apiUrl}/custom/api/jeu/sendMailJoueurs`;

    return this.http.post(url, data);
  }
}
