import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Joueur } from '@models/joueur';
import { User } from '@models/user';
import { JoueurService } from '@services/joueur.service';
import { UserService } from '@services/user.service';
import { MediaObjectService } from '@services/media-object.service';

@Component({
  selector: 'app-joueur-edit',
  templateUrl: './joueur-edit.component.html',
  styleUrls: ['./joueur-edit.component.scss']
})
export class JoueurEditComponent implements OnInit {
  idJoueur : number;
  joueur : Joueur;
  objectifPrive : string;
  objectifPublic : string;
  allFreeUsers : any =  [];
  iriUser : string;
  isSubmitting : boolean = false;
  returnUrl = null;
  dossierPersonnel: any;
  dossierPersonnelName: string;

  constructor(
    private route : ActivatedRoute,
    private joueurService : JoueurService,
    private router : Router,
    private userService : UserService,
    private mediaObjectService: MediaObjectService
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    this.idJoueur = this.route.snapshot.params.idJoueur;
    this.getOneJoueur();
  }

  getOneJoueur(){
    this.joueurService.getOne(this.idJoueur).subscribe(
      (data)=>{
        this.joueur = data;
        this.getFreeUsers();
        this.dossierPersonnelName = this.extractFilename(this.joueur?.dossierPersonnel?.contentUrl);
      }
    );
  }
  getFreeUsers(){
    this.userService.getFreeUser().subscribe(
      (user)=>{
        this.allFreeUsers.push(user);

        this.allFreeUsers = this.allFreeUsers[0];
        this.allFreeUsers.push({...this.joueur.user});


      }
    );
  }

  navigateToNext(){
    if(this.returnUrl){
      this.router.navigate([this.returnUrl]);
    }
    else{
      this.router.navigate([`/joueur/${this.idJoueur}/show`]);
    }
  }

  update(joueur : Joueur){
    this.isSubmitting = true;
    const joueur_value = {
      'id' : joueur.id,
      'type' : joueur.type['@id'],
      'equipe' : joueur.equipe['@id'],
      'user' : joueur.user ? joueur.user['iri'] : null,
      'description': joueur.description,
      'objectifPrive': joueur.objectifPrive,
      'objectifPublic': joueur.objectifPublic,
    };


    if(this.dossierPersonnel){
      const formData = new FormData();
      formData.append("file", this.dossierPersonnel);

      this.mediaObjectService.createMediaObject(formData).subscribe(mediaObject => {
        joueur_value['dossierPersonnel'] = mediaObject['@id'];
        this.updateJoueur(joueur_value);
      });

    }else{
      this.updateJoueur(joueur_value);
    }
  }

  updateJoueur(value){
    this.joueurService.update(value).subscribe(
      (joueur)=>{
        this.navigateToNext();
      },
      (error)=>null,
      ()=>{
        this.isSubmitting = false;
      }
    );
  }

  onFileChange(event){
    let files = event.target.files;

    if(files.length > 0){
      let file = files[0];
      this.dossierPersonnel = file;
      this.dossierPersonnelName = this.dossierPersonnel.name;
    }
  }

  extractFilename(s: string){
    if(this.joueur.dossierPersonnel){
      let filenameCopy = s.split('/')[s.split('/').length-1];
      let filename = filenameCopy.split('_').slice(1).join('_');
      return filename;
    }
  }
}
