import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  constructor() { }

  playBreakingNews(){
    const audio = new Audio();

    audio.src = "../../assets/audio/breaking-news-sound-1.mp3";
    audio.load();
    audio.volume = 0.1;

    try{
      audio.play();
    }catch(e){
      console.log(e);
    }
  }
}
