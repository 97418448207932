import { Component, OnInit } from '@angular/core';
import { Jeu } from '@models/jeu';
import { ActivatedRoute, Router } from '@angular/router';
import { JeuService } from '@services/jeu.service';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from '@models/user';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-edition-animation',
  templateUrl: './edition-animation.component.html',
  styleUrls: ['./edition-animation.component.scss']
})
export class EditionAnimationComponent implements OnInit {
  idJeu: number;
  jeu: Jeu;
  editionForm = new FormGroup({
    'dateDebut': new FormControl(''),
    'animateur': new FormControl(''),
    'observateurs': new FormControl('')
  });
  allAnimateurs: User[] = [];

  loadingValider = false;
  loadingSubmitAnimation = false;
  cities: any[] = [];

  freeUsers: User[] = [];
  currentAnimateurId = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jeuService: JeuService,
    private userService: UserService
  ) {
    this.cities = [
      {name: 'New York', code: 'NY'},
      {name: 'Rome', code: 'RM'},
      {name: 'London', code: 'LDN'},
      {name: 'Istanbul', code: 'IST'},
      {name: 'Paris', code: 'PRS'}
  ];
  }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.getJeu();
    this.getAnimateurs();
    this.getFreeUsers();
  }

  formatDate(date: string){
    return date.substring(0, 10);
  }

  getJeu(){
    this.jeuService.getOne(this.idJeu).subscribe(jeu => {
      this.jeu = jeu;
      console.log('this. date', this.formatDate(this.jeu.debut));
      this.editionForm.patchValue({
        'dateDebut': this.formatDate(this.jeu.debut)
      });
      this.getAnimateurJeu();
      this.getObservateursJeu();
    });
  }

  getAnimateurs(){
    this.userService.getAnimateur().subscribe(animateurs => {
      this.allAnimateurs = animateurs;
    })
  }

  getAnimateurJeu(){
    this.userService.getAnimateurJeu(this.jeu.id).subscribe(animateur => {
      this.currentAnimateurId = animateur.id;

      var animateursWithId = this.allAnimateurs.filter(x => x.id == this.currentAnimateurId);
      console.log('anwith', animateursWithId);
      if(animateursWithId.length > 0){
        this.editionForm.patchValue({
          'animateur': this.currentAnimateurId
        });
      }

    });
  }

  getObservateursJeu(){
    this.userService.getObservateursJeu(this.jeu.id).subscribe(observateurs => {
      var observateursIds = observateurs.map(x => x.id);

      var selectedObservateurs = this.freeUsers.filter(user => observateursIds.includes(user.id));

      this.editionForm.patchValue({
        'observateurs': selectedObservateurs
      });
    });
  }

  getFreeUsers(){
    this.userService.getFreeUser().subscribe(
      (users)=>{
        this.freeUsers = [...users];
      }
    );
  }

  submitEditionAnimation(){
    let data = this.editionForm.value;

    this.loadingSubmitAnimation = true;
    this.jeuService.setJeuForAnimation(this.jeu.id, data).subscribe(() => {
      this.loadingSubmitAnimation = false;
      this.router.navigate(['/animations']);
    });
  }
}
