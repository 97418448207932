import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { UserService } from '@services/user.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { selectJeuId } from '../core/selectors/jeu.selector';
import { Observable } from 'rxjs';
import { JeuState } from '@app/core/states/jeu.state';
import { SseService } from '@services/sse.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  items: MenuItem[];
  administrateur_menus: MenuItem[];
  animateur_menus: MenuItem[];
  jeu_animation_menus = [];

  jeuId$: Observable<number>;
  jeuId: number;

  openedGameDesigner: boolean = false;

  labelMenuClasses = {
    'label-menu': true,
    'openIcon': this.openedGameDesigner
  };

  ulMenuClasses = {
    'openMenu': this.openedGameDesigner
  };

  activeLang = 'fr';
  loadingLogout: boolean = false;
  translatedText: any;

  leftMenuOpen = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private userService: UserService
    ) {
      this.translate
      .stream([
        'mon_animation',
        'assigner_role_joueurs'
      ])
      .subscribe((resultat) => {
        this.translatedText = resultat;
      });
    }

  ngOnInit(): void {

    this.jeuId$ = this.store.pipe(select(selectJeuId));

    this.jeu_animation_menus = [
      {
        label: 'Messagerie',
        routerLink: ['/messagerie/' + 1],
        routerLinkActiveOptions: { exact: false }
      }
    ];



    this.jeuId$.subscribe(data => {
      console.log('jeuId', data);
      this.jeuId = data;

      console.log('translated text', this.translatedText);
      this.jeu_animation_menus = [
        // {
        //   label: 'Messagerie',
        //   routerLink: ['/messagerie/' + this.jeuId],
        //   routerLinkActiveOptions: { exact: false },
        //   classIco: 'csg-icomessagerie'
        // },
        
        // {
        //   label: 'Mes équipes',
        //   routerLink: ['/equipe/' + this.jeuId + '/list'],
        //   routerLinkActiveOptions: { exact: false },
        //   classIco: 'csg-icoequipes'
        // },
        // {
        //   label: 'Breaking News',
        //   routerLink: ['/breaking-new/' + this.jeuId],
        //   routerLinkActiveOptions: { exact: false },
        //   classIco: 'csg-icobreakingnews'
        // },
        // {
        //   label: 'Nouveautés',
        //   routerLink: ['/salon-nouveaute/' + this.jeuId],
        //   routerLinkActiveOptions: {exact: false},
        //   classIco: 'csg-iconouveautes'
        // },
        {
          label: this.translatedText?.assigner_role_joueurs,
          routerLink: ['/joueur/' + this.jeuId + '/list'],
          routerLinkActiveOptions: {exact : false},
          classIco : 'csg-icojoueurs'
        },
        {
          label: this.translatedText?.mon_animation,
          routerLink: ['/animation/' + this.jeuId],
          routerLinkActiveOptions: { exact: false },
          classIco: 'csg-icojeux'
        },
        // {
        //   label: 'Timeline',
        //   routerLink: ['/timeline/' + this.jeuId],
        //   routerLinkActiveOptions: {exact : false},
        //   classIco : 'csg-icojoueurs'
        // }
      ];

    });

    this.administrateur_menus = [
      {
        label: 'Utilisateurs',
        icon: 'pi pi-pw pi-users',
        routerLink: ['/users'],
        routerLinkActiveOptions: { exact: false }
      },
      {
        label: 'Types de jeux',
        routerLink: ['/type-jeu'],
        routerLinkActiveOptions: { exact: false }
      },
      {
        label: 'Types d\'équipes',
        routerLink: ['/type-equipes'],
        routerLinkActiveOptions: { exact: false }
      },
      {
        label: 'Types de Joueurs',
        routerLink: ['/type-joueur'],
        routerLinkActiveOptions: { exact: false }
      },
      {
        label: 'Jeux',
        routerLink: ['/jeux'],
        routerLinkActiveOptions: { exact: false }
      },
      {
        label: 'Produits',
        routerLink: ['/produits'],
        routerLinkActiveOptions: { exact: false}
      },
      {
        label: 'Ressources',
        routerLink: ['/ressource'],
        routerLinkActiveOptions: { exact: false }
      }

    ];



  }

  is_active(): boolean {
    if (this.authService.currentUserValue !== null && this.authService.currentUserValue.token)
      return true;
    else
      return false;
  }

  user_show_link() {
    if (this.is_active()) {
      return `/users/show/${this.authService.currentUserInfo.id}`;
    } else {
      return "";
    }
  }

  get username() {
    return this.authService.currentUserInfo ? this.authService.currentUserInfo.username : '';
  }

  logout() {
    this.loadingLogout = true;
    this.userService.logout().subscribe(() => {
      this.authService.logout();
      // this.loadingLogout = false;
      this.router.navigate(['/login']);
    });
  }

  toggleGameDesigner(e)
  {
    e.preventDefault();
    this.openedGameDesigner = ! this.openedGameDesigner;

  }

  changeLanguage(e, lang: string) {
    e.preventDefault();
    console.log('change language');
    this.activeLang = lang;
    this.translate.use(lang);
  }

  toggleLeftMenu(){
    this.leftMenuOpen = !this.leftMenuOpen;
  }
}
