import { MediaObject } from './../../models/media-object';
import { MediaObjectService } from './../../services/media-object.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { User, UserAdapter } from '@models/user';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { Role } from '@models/role';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  add_animator: boolean;
  roles: string[];
  role_number: string;
  id : string;
  url: string;
  editForm : FormGroup;
  user$: Observable <User>;
  itemImageUrl;
  photoSelected$: Observable <MediaObject>;
  options = [
    { id: 1, display: "User" },
    { id: 2, display: "Animateur" },
  ]

  isSubmitting = false;

  constructor(
    private mediaservice: MediaObjectService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private route:ActivatedRoute,
    private goroute:Router,
    private userAdpter: UserAdapter,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.user$ = this.userService.getUser(this.id);
    this.user$.subscribe(
      (res) => {
        this.itemImageUrl = this.mediaservice.contentUrl(res.photo);
        this.roles = res.roles;
        this.role_number = this.getRoleNumber(this.roles);
    });
    this.initForm();
  }

  initForm(){
    this.editForm = this.formBuilder.group(
      {
        roles: [''],
        username : [''],
        firstname : [''],
        lastname : [''],
        email : [''],
        phone : [''],
        organisation : [''],
        description : [''],
        photo : [''],
        photo_source : ['']
      }
    );
  }

  getRoleNumber(arr: string[]){
    var j = 0;
    for (var i = 0; i < arr.length; i++) {
      if(arr[i] == Role.animateur){
        j++;
        break;
      }
    }
    if(j==0){
      return "1";
    }
    else{
      return "2";
    }
  }

  removeROLE_USER(user: User){
    if(user.roles[0] == Role.user){
      user.roles.splice(0, 1);
    }
  }

  switchToAnimator(user: User){
    var j = 0;
    for (var i = 0; i < user.roles.length; i++) {
      if(user.roles[i] == Role.animateur){
        j++;
        break;
      }
    }
    if(j==0){
      user.roles.push(Role.animateur);
      user.roles.push(Role.admin);
    }
  }

  removeFromAnimator(user: User){
    // for (var i = 0; i < user.roles.length; i++) {
    //   if(user.roles[i] == Role.animateur){
    //     user.roles.splice(i, 1);
    //     break;
    //   }
    // }
    user.roles = user.roles.filter(role => role != Role.animateur && role != Role.admin);
  }

  onselecFile(e){
    if(e.target.files.length>0){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.itemImageUrl = e.target.result;
      }
      const file = e.target.files[0];
      this.editForm.get('photo_source').setValue(file);
    }
  }

  changeRole(e){
    if(e.target.value == "2"){
      this.add_animator = true;
    }
    else{
      this.add_animator = false;
    }
  }

  onSave(){
    if(this.editForm.valid){
      if(this.editForm.get('photo_source').value==''){
        var usr = this.editForm.value as User;
        var y: number = +this.id;
        usr.id=y;
        usr.roles=this.roles;
        if(this.add_animator){
          this.switchToAnimator(usr);
        }
        else{
          this.removeFromAnimator(usr);
        }
        this.removeROLE_USER(usr);
        usr = this.userAdpter.noPhotoAdapt(usr);
        this.isSubmitting = true;
        this.userService.updateUser(usr).subscribe(
          () => {
          this.goroute.navigate(['/users/show/'+this.id]);}
        );
      }else{
        const formData = new FormData();
        formData.append('file', this.editForm.get('photo_source').value);
        this.mediaservice.createMediaObject(formData).subscribe(
          (res) => {
          var usr = this.editForm.value as User;
          usr.photo=res['@id'];
          var y: number = +this.id;
          usr.id=y;
          usr.roles=this.roles;
          if(this.add_animator){
            this.switchToAnimator(usr);
          }
          else{
            this.removeFromAnimator(usr);
          }

          this.isSubmitting = true;
          this.userService.updateUser(usr).subscribe(() => {
            this.goroute.navigate(['/users/show/'+this.id]);
          });
        });
      }
    }
  }

  annulerModificationUser(){
    this.goroute.navigate(['/users/list']);
  }
}
