<body>
  <p-toast position="top-left"></p-toast>
  <div
    class="loading-layer"
    *ngIf="(!jeu && isAnimateur) || (!joueur && !jeu && !isAnimateur)"
  >
    <mat-spinner></mat-spinner>
    <div class="load-fix"><img src="/assets/images/load-fix.png" alt="" /></div>
  </div>
  <div
    class="player-layout"
    *ngIf="(joueur && jeu && !isAnimateur) || (jeu && isAnimateur)"
  >
    <!---LEFT COL -->
    <div class="left-player">
      <!---debut header menu -->
      <div class="header player">

        <div class="logo-container-player">
        <a href="" class="logo">
          <img src="/assets/images/logo-blanc.png" />
        </a>
        </div>



        <div class="nav-timeline-container">
        <div class="game-round">
          <ul class="timeline">
            <li [ngClass]="{ 'round-active': jeu.tour == 0 }">{{ 'layout_intro' | translate }}</li>
            <li [ngClass]="{ 'round-active': jeu.tour == 1 && jeu.isWarmup != '0' }" *ngIf="jeu.hasWarmup">{{ jeu.equivalentPeriode }} 0<br><span class="warmup-text">{{ 'warmup' | translate }}</span></li>
            <li [ngClass]="{ 'round-active': jeu.tour == t+1 && jeu.isWarmup == '0' }" *ngFor="let temp of [].constructor(+jeu.nbTours); let t = index">{{ jeu.equivalentPeriode }} {{ t+1 }}</li>
            <li [ngClass]="{ 'round-active': jeu.tour == -1 }">{{ 'layout_debrief' | translate }}</li>
          </ul>
        </div>

        <div>
          <ul class="menu">
            <li>
              <a class="lang" href="" [class.active-lang] = "activeLang == 'fr'" (click)="changeLanguage($event, 'fr')">
                <img src="/assets/images/fr.png" alt="" />
              </a>
            </li>
            <li>
              <a class="lang" href="" [class.active-lang] = "activeLang == 'en'" (click)="changeLanguage($event, 'en')">
                <img src="/assets/images/en.png" alt="" />
              </a>
            </li>
            <li>
              <a class="logout-player" (click)="logout()">
                <img src="/assets/images/logout.svg" alt="" *ngIf="!loadingLogout"/>
                <span class="spinner-border spinner-border-sm" *ngIf="loadingLogout"></span>
              </a>
            </li>
          </ul>
        </div>

        </div>



      </div>
      <!---fin header menu -->

      <!---debut game header -->
      <div class="game-header">
        <div class="game-name" (click)="navigateToPage()">
          <ng-container *ngIf="!isMessagerie">
            <span class="material-icons-outlined"> sports_esports </span>
            <span class="game-name-span">{{ jeu.nom }}</span>
          </ng-container>
          <ng-container *ngIf="isMessagerie">
            <span><mat-icon>arrow_back</mat-icon></span>
          </ng-container>
        </div>

        <div class="phase" *ngIf="jeu.tour != -1">
          <ng-container *ngIf="jeu.tour != 0">
            <ul>
              <li [class]="{ 'active-phase': activePhase == 'annonces' }">
                {{ 'layout_annonce' | translate }}
              </li>
              <li [class]="{ 'active-phase': activePhase == 'decisions' }">
                {{ 'layout_decision' | translate }}
              </li>
              <li
                [class]="{ 'active-phase': activePhase == 'pitchs' }"
                *ngIf="
                  this.jeu.currentAction &&
                    this.jeu.currentAction.type == 'pitchs' &&
                    this.jeu.currentAction.parentAction;
                  else simplePitch
                "
              >
                {{ 'layout_pitch' | translate }}: {{ this.jeu.currentAction.nom }}
              </li>
              <ng-template #simplePitch>
                <li [class]="{ 'active-phase': activePhase == 'pitchs' }">
                  {{ 'layout_pitch' | translate }}
                </li>
              </ng-template>
              <li [class]="{ 'active-phase': activePhase == 'votes' }">
                {{ 'layout_votes' | translate }}
              </li>
              <li [class]="{ 'active-phase': activePhase == 'resultats' }">
                {{ 'layout_resultat' | translate }}
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="jeu.tour == 0">
            <ul>
              <li class="active-phase">
                <ng-container *ngIf="this.jeu.currentAction">{{
                  this.jeu.currentAction.nom
                }}</ng-container>
              </li>
            </ul>
          </ng-container>
        </div>
        <!-- <div class="phase" *ngIf="phasesJeu">
              <ul>
                <li *ngFor="let phase of phasesJeu">
                  {{ phase.nom }}
                </li>
              </ul>
            </div> -->

        <!-- <app-timer [departTime]="startedTime"></app-timer> -->
        <app-timer-phase
          [phase]="jeu.currentAction"
          [withPause]="isAnimateur"
          (endedTime)="onPhaseFinished()"
        ></app-timer-phase>
      </div>


      <div class="messenger" [class.open]="openedModalMessagerie">
        <span class="close-messenger" (click)="closeMessenger()">
          <mat-icon>close</mat-icon>
        </span>
        <!-- <div class="tchat-layout">
          <div class="tchat-content">
            <div class="sender-message">
               <div class="message-container">
                 <div class="message">Message</div>
                 <div class="time-message">time</div>
               </div>
            </div>


            <div class="my-message">
              Mon message
            </div>
          </div>


          <div class="tchat-send">
             <form autocomplete="off">
                <div class="text-edit">
                  <input
                    type="text"
                    id="message"
                    name="message"
                    autocomplete="off"
                  />
                </div>
                <div class="action-tchat">
                  <input type="submit" value="{{ 'envoyer' | translate }}"/>
                </div>
             </form>
          </div>

        </div> -->
        <ng-container *ngIf="room && joueur">
          <h2>{{ room.nom }}</h2>
          <app-chat-messagerie [room]="room" [joueur]="joueur"></app-chat-messagerie>
        </ng-container>
      </div>



      <!---fin game header -->

      <!---debut content player -->
      <!--<div class="content-player">


          </div>-->
      <!-- <ng-container *ngIf="jeu && joueur"> -->
      <router-outlet></router-outlet>
      <!-- </ng-container> -->
      <!---fin content player -->

      <!---debut breaking news -->
      <div class="fil-breaking-news">
        <div class="break-logo">
          <img src="/assets/images/break-logo.png" />
        </div>
        <div class="txt">
          <span *ngIf="breakingNew">{{ breakingNew.nom }}: {{ breakingNew.description }}</span>
        </div>
      </div>
      <!---fin breaking news -->
    </div>

    <!---RIGHT COL -->
    <div class="right-player" [ngClass]="status ? 'true' : 'false'">
       <div class="open" [ngClass]="status ? 'true' : 'false'" (click)="clickEvent()"
    >
     <div class="dash"></div>
	<div class="dash"></div>
	<div class="dash"></div>
	<div class="dash"></div>
</div>

      <div class="profil">
        <div class="user-profil" *ngIf="!loadingCheckObservateur && !isAnimateur && !isObservateur">
          <div class="profil-personnel">
            <div class="photo-card">
              <div class="photo-container">
                <div class="user-photo">
                  <ng-container *ngIf="user && !user.photo">
                    <img src="/assets/images/hector.jpg" />
                  </ng-container>
                  <ng-container *ngIf="user && user.photo">
                    <img [src]="user.photo.contentUrl | contentUrl" />
                  </ng-container>
                </div>
                <div class="action-perso" (click)="showDescriptionPerso()">
                  <a href="#" class="link-button dossier-personnel">
                    <span class="material-icons-outlined"> description </span>
                  </a>
                </div>
              </div>

              <h3 *ngIf="joueur.user">{{ joueur.user["nom"] }}</h3>
              <span class="orange-bold title-user" *ngIf="joueur['type']"
                >{{ joueur["type"]["traductionKey"] | translate }}
                <br />
                <span *ngIf="joueur.equipe">{{ joueur.equipe.nom }}</span>
              </span>
            </div>

            <div class="user-info">
              <div class="entreprise-info">
                <div class="entreprise-photo" *ngIf="joueur.equipe">
                  <!-- <img src="/assets/images/rolex.jpg"> -->
                  <img
                    [src]="contentUrl(joueur.equipe['logo']['contentUrl'])"
                    *ngIf="joueur.equipe['logo']"
                  />
                </div>

                <!-- Resultat Equipe -->
                <div class="entreprise-bourse">
                  <span class="gris-light up-txt"
                    >{{ infoEquipe["titreResultat"] }}
                  </span>

                  <div class="bourse-value">
                    <span class="budget">{{
                      infoEquipe["resultat"] | number: "1.0-1"
                    }}</span>

                    <span
                      *ngIf="infoEquipe['increase'] > 0 && tourResultat >= 1"
                      class="etat-bourse green"
                    >
                      <span class="material-icons-outlined">
                        arrow_circle_up </span
                      >{{ infoEquipe["increase"] | number: "1.0-1" }} %
                    </span>
                    <span
                      *ngIf="infoEquipe['increase'] < 0"
                      class="etat-bourse red"
                    >
                      <span class="material-icons-outlined">
                        arrow_circle_down </span
                      >{{ -infoEquipe["increase"] | number: "1.0-1" }} %
                    </span>
                    <span
                      *ngIf="infoEquipe['increase'] == 0"
                      class="etat-bourse"
                    >
                      -- 0 %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isAnimateur || isObservateur">
        <span><span class="csg-icoprofil"></span> {{username}} <span *ngIf="isObservateur">(observateur)</span></span>
      </ng-container>

      <ng-container
        #messagerieDroite
        *ngIf="
          !joueur || (joueur && joueur.equipe && joueur.equipe['type']['ecosysteme'] != 'client') || isObservateur
        "
      >
        <div class="titre-section">
          <h3 class="hover-hand" (click)="goToMessagerie($event)">
            <span class="material-icons-outlined"> sms </span>
            <span
              *ngIf="unseenMessage != 0"
              matBadge="!"
              matBadgePosition="before"
              class="highlight-badge"
              >{{ 'layout_messagerie_secrete' | translate }}</span
            >
            <span *ngIf="unseenMessage == 0">{{ 'layout_messagerie_secrete' | translate }}</span>
            <span
              class="popover-tips"
              placement="bottom"
              triggers="mouseenter:mouseleave"
              popoverTitle="{{ 'layout_messagerie_secrete' | translate }}"
              ngbPopover="{{ 'messagerie_secrete_explication' | translate }}"
            >
              ?
            </span>
          </h3>
          <a href="" (click)="goToMessagerie($event)">...</a>
        </div>

        <div class="tchat" #tchatContent>
          <!-- <div class="tchat-item" *ngFor="let message of lastMessages">
            <div class="tchat-photo">
              <img
                [src]="contentUrl(message.joueur.equipe['logo']['contentUrl'])"
              />
            </div>
            <div class="tchat-message">
              <div class="tchat-user">
                <span class="name" *ngIf="!message.messageSysteme">{{ message.joueur.equipe.nom }}</span>
                <span class="name" *ngIf="message.messageSysteme">{{ 'layout_service_technique' | translate }}</span>
                <span class="timechat">{{
                  message.createdAt | date: "HH:mm"
                }}</span>
              </div>
              <div class="tchat-mp">
                {{ message.text }}
              </div>
            </div>
          </div> -->


          <div class="tchat-item" *ngFor="let room of rooms" (click)="showModalMessagerie(room.id)" [class.non-lu]="roomMessages && roomMessages[room.id] && roomMessages[room.id].unseen" [class.systeme]="room.type == 'systeme'">
            <div class="pastille" *ngIf="roomMessages && roomMessages[room.id] && roomMessages[room.id].unseen">
              !
            </div>

            <div class="tchat-photo">
              <img [src]="('/media/' + room.logoPath) | contentUrl">
            </div>
            <div class="tchat-message">
              <div class="tchat-user">
                <span class="name">{{ room.nom }}</span>
                <!-- <span class="timechat">05:11</span> -->
              </div>
              <div class="tchat-mp">
               <span *ngIf="roomMessages && !roomMessages[room.id]">{{ 'pas_nouveau_message' | translate }}</span>
               <span *ngIf="roomMessages && roomMessages[room.id]">{{ roomMessages[room.id].text }}</span>
              </div>
            </div>
          </div>




        </div>
      </ng-container>

      <div class="titre-section">
        <h3 class="hover-hand">
          <span class="material-icons-outlined"> article </span>{{ 'layout_journal_evenement' | translate }}
          <span
            class="popover-tips"
            placement="bottom"
            triggers="mouseenter:mouseleave"
            popoverTitle="{{ 'layout_journal_evenement' | translate }}"
            ngbPopover="{{ 'journal_evenement_explication' | translate }}"
          >
            ?
          </span>
        </h3>
        <a href="">...</a>
      </div>

      <app-journal-liste [jeu]="jeu" [isAnimateur]="isAnimateur"></app-journal-liste>

      <ng-container *ngIf="isAnimateur">
        <div class="titre-section">
          <h3 [class.hover-hand]="isAnimateur">
            <span class="material-icons-outlined" [class.hover-hand]="isAnimateur"> sms </span>{{ 'layout_commentaire' | translate }}
            <span
              class="popover-tips"
              placement="bottom"
              triggers="mouseenter:mouseleave"
              popoverTitle="Commentaire animateur"
              ngbPopover="Vous pouvez commenter au fil de l'eau sur les évènements"
            >
              ?
            </span>
          </h3>
        </div>

        <app-commentaire-debrief [jeu]="jeu"></app-commentaire-debrief>
      </ng-container>
    </div>
  </div>

  <ng-template #descriptionPersoModal let-descriptionPersoModal>
    <div class="modal-header">
      {{ 'layout_dossier_personnel' | translate }}
      <button
        type="button"
        class="close"
        aria-label="Fermer"
        (click)="closeDescriptionPerso()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="!isAnimateur">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>{{ joueur.equipe.nom }}</h2>
            <h4 class="header-description">{{ 'layout_description_role' | translate }}</h4>
            <p>
              {{ joueur.description }}
              <!-- Nous sommes Directeurs du Développement et des Risques de PARDI,
              L’un des leaders mondiaux du Luxe. <br />
              Nous avons laissé trop longtemps BACIENLAGA PARIS tranquille sur
              son marché Franco-Européen, cela doit cesser ! Nous devons
              reconquérir nos parts de marché sur le vieux continent. Ca doit
              saigner (dixit nos actionnaires américains) !!! -->
            </p>

            <h4 class="header-description">{{ 'layout_objectifs_prives' | translate }}</h4>
            <p>
              {{ joueur.objectifPrive }}
              <!-- Essayer de déstabiliser nos concurrents, tous les coups sont
              permis…<br />
              Prendre un maximum de parts de marché à l'adversaire, BACIENLAGA
              PARIS, afin d'imposer un rapport de force<br />
              Essayer de "séduire" les représentant de l’ETAT et leur faire
              comprendre qu'avec notre réseau, réellement mondial, et de plus en
              plus digital, nous pourrions offrir paradoxalement un meilleur
              miroir du luxe national grâce à des productions et services
              développés dans l’Hexagone et repris dans le groupe mondial.<br />
              Pouvoir exécuter au mieux la stratégie DigitalFirst sur le réseau
              Européen, afin d'impressionner la maison mère US... et de
              remplacer l'actuel CEO Monde et Chairman, Harry, qui d'après la
              rumeur, pourrait prendre très vite sa retraite... -->
            </p>

            <button
              (click)="dossierPersonnel.click()"
              mat-raised-button
              color="warn"
            >
              <span class="material-icons-outlined">file_download</span>
              {{ 'layout_telechargement_fiche' | translate }}
            </button>

            <a
              #dossierPersonnel
              href="{{ contentUrl(joueur.dossierPersonnel?.contentUrl) }}"
              download="dossier_personnel.pdf"
              style="display: none"
              target="_blank"
              >{{ 'layout_dossier_personnel' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalMessagerie>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Fermer"
        (click)="closeModalMessagerie()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="room && joueur">
        <h2>{{ room.nom }}</h2>
        <app-chat-messagerie [room]="room" [joueur]="joueur"></app-chat-messagerie>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #modalBreakingNews>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Fermer"
        (click)="closeModalBreakingNews()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="img-container-modal">
        <img src="/assets/images/breaking_news.jpg">
      </div>
      <ng-container *ngIf="breakingNewPop">
        <h2 class="text-center title-breaking-news">{{ breakingNewPop.createur }}</h2>
        <div class="contenu-breaking-news">
          <h3>{{ breakingNewPop.nom }}</h3>

          <p>
            {{ breakingNewPop.description }}
          </p>
        </div>

      </ng-container>
    </div>
  </ng-template>

  <ng-template #modalVirements>
    <div class="modal-header">
      <button
        type="button"
        class="close"
        aria-label="Fermer"
        (click)="closeModalVirements()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container>
        <h2 class="text-center title-modal-virements">Virements</h2>
        <div class="contenu-breaking-news">
          Les virements suivants ont été exécutés

          <div *ngIf="virements">
            <ul class="virement-list">
              <li *ngFor="let virement of virements">
                {{ virement.montant }} M euros de {{ virement.source }} vers {{ virement.destination }}
              </li>
            </ul>
          </div>
        </div>

      </ng-container>
    </div>
  </ng-template>
</body>
