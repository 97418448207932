<div class="admin">

    <header class="header">
			<a href="" class="logo">
				<img src="/assets/images/logo-blanc.png">
			</a>

			<input class="menu-btn" type="checkbox" id="menu-btn" />
			<label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
			<ul class="menu">
        <!-- <li><a routerLink="/animations">Mes animations</a></li> -->
        <li [class.active-lang] = "activeLang == 'fr'">
          <a class="lang" href=""  (click)="changeLanguage($event, 'fr')">
            <img src="/assets/images/fr.png" alt="" />
          </a>
        </li>
        <li [class.active-lang] = "activeLang == 'en'">
          <a class="lang" href=""  (click)="changeLanguage($event, 'en')">
            <img src="/assets/images/en.png" alt="" />
          </a>
        </li>

			  <li>
          <a class="logout" (click)="logout()">
            <img src="/assets/images/logout.svg" alt="" *ngIf="!loadingLogout"/>
            <span class="spinner-border spinner-border-sm" *ngIf="loadingLogout"></span>
          </a>
        </li>
			</ul>
		  </header>


    <div class="container-custom global">
    <div class="user-col" [class.open]="leftMenuOpen">
      <div class="user-profil">
        <div class="ico-photo">
          <img src="/assets/images/play.png">
        </div>
        <!--
          <div class="user-info">
          <h3>Hector</h3>
          <span class="orange-bold">Accès provisoire</span>
          <span class="gris-light">Jusqu'au 20 Août 2021</span>
        </div>
        !-->
      </div>

      <div class="user-menu">
        <span><span class="csg-icoprofil"></span> {{username}}</span>
        <h1>{{ 'dashboard_administrateur' | translate }}</h1>
        <ul>
          <li>
            <a routerLink="/users" routerLinkActive="active">
              <span class="csg-icoprofil"></span>
              <span class="label-menu">{{ 'dashboard_users' | translate }}</span>
            </a>
          </li>

          <!-- <li class="hadChild"><a href="" (click)="toggleGameDesigner($event)"><span class="csg-icodesigner"></span><span class="label-menu" [class.openIcon]="openedGameDesigner">Game designer</span></a>

            <ul [class.openMenu]="openedGameDesigner">
              <li><a routerLink="/type-equipes">Type d'équipes</a></li>
              <li><a routerLink="/type-joueur">Type de joueurs</a></li>
              <li><a routerLink="/produits">Produits</a></li>
            </ul>

          </li> -->
          <li>
            <a routerLink="/produits" routerLinkActive="active">
              <span class="csg-icodesigner"></span>
              <span class="label-menu">{{ 'produits' | translate }}</span>
            </a>
          </li>
          <!--<li>
            <a routerLink="/produits" routerLinkActive="active">
              <span class="csg-icoproduit"></span>
              <span class="label-menu">Produits</span>
            </a>
          </li> -->

          <li>
            <a routerLink="/jeux" routerLinkActive="active">
              <span class="csg-icojoueurs"></span>
              <span class="label-menu">{{ 'dashboard_jeux' | translate }}</span>
            </a>
          </li>
          <li>
            <a routerLink="/animations" routerLinkActive="active">
              <span class="csg-icojeux"></span>
              <span class="label-menu">{{ 'dashboard_mes_animations' | translate }}</span>
            </a>
          </li>


        </ul>

        <h1 *ngIf="jeuId != 0">{{ 'dashboard_animateur' | translate }}</h1>
        <ul>
          <ng-container *ngIf="jeuId != 0">
            <li *ngFor="let menu_item of jeu_animation_menus">
              <a routerLink="{{ menu_item.routerLink }}" routerLinkActive="active">
                <span class="{{ menu_item.classIco }}"></span>
                <span class="label-menu">{{ menu_item.label }}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="sink" (click)="toggleLeftMenu()"><span class="csg-icosink" [class.open]="leftMenuOpen"></span></div>
    </div>

    <div class="content-col">
        <div class="data-col">
          <router-outlet></router-outlet>
        </div>
    </div>
    </div>







  </div>
