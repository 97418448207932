
<div class="container" *ngIf="jeu">
  <div class="row no-gutters">
    <h1>{{ 'jeu' | translate }} {{ jeu.nom }} - Round : <strong *ngIf="jeu.tour == -1">Debrief</strong><strong *ngIf="jeu.tour > 0">{{jeu.tour}}</strong><strong *ngIf="jeu.tour == 0">{{ 'introduction' | translate }}</strong></h1>
    <h4 *ngIf="jeu.started" class="ml-auto mt-auto">{{ 'jeu_en_cours' | translate }}</h4>
  </div>
  <p>
  <!--<button *ngIf="!jeu.started" mat-raised-button color="primary" class="mr-2" (click)="startGame()">Lancer</button>-->
  <button mat-raised-button color="primary" class="mr-2" (click)="animerJeu()" [disabled]="loadingAnimerJeu || !animateurCanLaunch || !joueursComplets || !paramsConfigured">
    <span class="spinner-border spinner-border-sm" *ngIf="loadingAnimerJeu"></span>
    {{ 'animer_le_jeu' | translate }}
  </button>
  <!--<button mat-raised-button color="warn" (click)="nextRound()" *ngIf="jeu.started">Round suivant</button>-->
  </p>

  <p class="red" *ngIf="!animateurCanLaunch">
    {{ 'not_animateur_jeu' | translate }}
  </p>
  <p class="red" *ngIf="!joueursComplets">
    {{ 'joueurs_not_all_assigned' | translate }}
  </p>
  <p class="red" *ngIf="!paramsConfigured">
    {{ 'params_not_configured' | translate }}
  </p>
  <p *ngIf="!jeu.started">{{ 'date' | translate }}: {{ jeu.debut | date : 'd-M-yy' }}</p>
  <!--<p *ngIf="jeu.started">Date du début: {{ jeu.debutReel | date : 'MMMM d, y, h:mm:ss a' }}</p>-->
  <!-- <p *ngIf="jeu.started"> Temps écoulé : <app-chronometre [dateDepart]="tempsDepart"></app-chronometre> </p> -->
  <h3>{{ 'round_actuel' | translate }}: <strong *ngIf="jeu.tour > 0">{{jeu.tour}}</strong><strong *ngIf="jeu.tour == 0">{{ 'introduction' | translate }}</strong><strong *ngIf="jeu.tour < 0">Debrief</strong></h3>


  <h2>{{ 'liste_equipes' | translate }}:</h2>
  <div *ngFor="let equipe of jeu.equipes, let equipeIndex = index">
    <mat-list>
      <h3 class="d-flex">
        <mat-icon class="mr-4">groups</mat-icon>{{ equipe.nom }} <em *ngIf="equipe.joueurs.length == 0"> : {{ 'aucun_joueur' | translate }}</em>
      </h3>
      <div *ngIf="equipe.joueurs.length > 0">
        <ng-container *ngFor="let joueur of equipe.joueurs">

          <mat-list-item *ngIf="joueur.user">
              <mat-icon mat-list-icon>person</mat-icon>
              <div mat-line> {{ joueur.user.username }}</div>
              <div mat-line> {{ joueur['type']['nom'] }}</div>
          </mat-list-item>
        </ng-container>
      </div>
    </mat-list>
    <mat-divider></mat-divider>
  </div>
<!--<button *ngIf="!jeu.started" mat-raised-button (click)="edit()">Modifier les équipes</button>-->

</div>
