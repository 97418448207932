import { ActionJeuService } from '@services/action-jeu.service';
import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ActionJeu } from '@models/action-jeu';

@Component({
  selector: 'app-timer-phase',
  templateUrl: './timer-phase.component.html',
  styleUrls: ['./timer-phase.component.scss']
})
export class TimerPhaseComponent implements OnInit, OnChanges {
  @Input() phase: ActionJeu;
  @Input() withPause: false;

  @ViewChild('timer') timer;

  @Output('endedTime') endedTime = new EventEmitter<any>();


  timerSeconds = 0;
  loadingPausePhase: boolean = false;
  loadingPlayPhase: boolean = false;

  paused: boolean = false;

  constructor(
    private actionJeuService: ActionJeuService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.paused = this.phase.paused;
    this.initTimer();
  }

  ngOnInit(): void {
    console.log('this.phase is', this.phase);
    this.paused = this.phase.paused;
    this.initTimer();
  }

  initTimer(){
    console.log('phase', this.phase);
    console.log('phase started', this.phase?.started);


    if(this.phase && this.phase.started){

      let dureeSeconds = this.phase.duree * 60;
      console.log('d', dureeSeconds);
      let leftSeconds = Math.max(0, dureeSeconds - this.phase.timePassed);
      console.log('timepassed', this.phase.timePassed);
      console.log('left seconds', leftSeconds);
      this.timerSeconds = leftSeconds;
    }
  }

  onPhaseFinished(){
    this.endedTime.emit();
  }

  pausePhase(){
    this.loadingPausePhase = true;
    this.actionJeuService.pausePhase(this.phase.id).subscribe(() => {
      this.loadingPausePhase = false;
      this.paused = true;
    });
  }

  playPhase(){
    this.loadingPlayPhase = true;
    this.actionJeuService.playPhase(this.phase.id).subscribe(() => {
      this.loadingPlayPhase = false;
      this.paused = false;
    });
  }
}
