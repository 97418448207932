<app-loading-grow *ngIf="loadingMessages"></app-loading-grow>

<div class="tchat-layout" *ngIf="!loadingMessages">
  <!-- <div class="tchat-header">
      <div class="tchat-photo"><img src="images/profil-tchat.png"></div>

      <div class="tchat-user">
          <span class="name">Julien brazavil</span>
          <span class="titre">Directeur des risques - Gacenliaba</span>
      </div>
    </div> -->

  <div class="tchat-content" #tchatContent>
    <ng-container *ngFor="let message of messages">
      <div
        class="sender-message"
        *ngIf="(allRooms || message.joueur.id != joueur.id) && !message.messageSysteme"
      >
        <div class="tchat-photo">
          <img *ngIf="message.joueur && message.joueur.equipe && message.joueur.equipe['logo']"
            [src]="contentUrl(message.joueur.equipe['logo']['contentUrl'])"
          />
        </div>

        <div class="message-container">
          <div class="message">
            <span class="room-information" *ngIf="isAnimateur && message.room">{{ 'dans' | translate }} {{ message.room.nom }}: </span> <strong *ngIf="message.joueur && message.joueur.equipe">({{ message.joueur.equipe.nom }})</strong> {{ message.text }}
          </div>
          <div class="time-message">
            {{ message.createdAt | date: "HH:mm" }}
          </div>
        </div>
      </div>

      <!-- Message Systeme -->
      <div
        class="sender-message"
        *ngIf="(allRooms || message.joueur.id != joueur.id) && message.messageSysteme"
      >

        <div class="message-container">
          <div class="message">
            <span class="room-information" *ngIf="isAnimateur">{{ 'dans' | translate }} {{ message.room.nom }}: </span> {{ message.text }}
          </div>
          <div class="time-message">
            {{ message.createdAt | date: "HH:mm" }}
          </div>
        </div>
      </div>

      <!-- Message my-message -->

      <div
        class="my-message"
        *ngIf="!allRooms && message.joueur.id == joueur.id && !message.messageSysteme"
      >
        {{ message.text }}
      </div>
    </ng-container>
    <!-- <div class="sender-message">
          <div class="tchat-photo"><img src="images/profil-tchat.png"></div>
          <div class="message">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
      </div>
      <div class="my-message">
          my message
      </div>
      <div class="sender-message">
          <div class="tchat-photo"><img src="images/profil-tchat.png"></div>
          <div class="message">uis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
      </div>
      <div class="my-message">
          At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus,
      </div> -->
  </div>

  <div class="tchat-send" *ngIf="!readOnly && room.type != 'systeme'">
    <form autocomplete="off">
      <div class="text-edit">
        <input
          type="text"
          id="message"
          [(ngModel)]="typedMessage"
          name="message"
          autocomplete="off"
        />
      </div>
      <div class="action-tchat">
        <input type="submit" value="{{ 'envoyer' | translate }}" (click)="sendMessage()" />
      </div>
    </form>
  </div>
</div>
