export interface JeuState{
  id: number,
  tour: number,
  started: boolean,
  nouvelleAnalyseMarche?: number;
  reponseSondage?: number;
}

export const initialState: JeuState = {
  id: 0,
  tour: 0,
  started: false,
  nouvelleAnalyseMarche : 0,
  reponseSondage: 0
}
