import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Adapter } from '../../models/adapter';

import { environment } from '../../../environments/environment';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export abstract class Lister<T> {
  protected countSubject = new BehaviorSubject<number>(0);
  count$ = this.countSubject.asObservable();

  protected listUri :string = '';
  constructor(protected http: HttpClient, protected adapter: Adapter<T>) {

  }

  setListUri(uri: string)
  {
    this.listUri = uri;
  }

  getAll(filter = {}, pageIndex = 0, pageSize = -1, orders = {}): Observable<T[]> {
    let p = new HttpParams()
      .set('_page', (pageIndex + 1).toString());

    if(pageSize != -1){
      p = p.set('itemsPerPage', pageSize.toString());
    }

    for (const k in orders) {
      p = p.set(`order[${k}]`, orders[k].toString());
    }

    for (const k in filter) {
      if(filter[k] != null){
        p = p.set(`${k}`, filter[k].toString());
      }else{
        p = p.set(`${k}`, null);
      }
    }

    p = p.set('exists[archived]', "false");

    return this.http.get(`${environment.apiUrl}/${this.listUri}`, {
      params: p
    }).pipe(
      tap((data: any) => {
        this.countSubject.next(data['hydra:totalItems']);
      }),
      map((data: any) => data['hydra:member']),
      map((t: any[]) => t.map(x => this.adapter.adapt(x)))
    );
  }

  getOne(id: number): Observable<T>{
    return this.http.get(`${environment.apiUrl}/${this.listUri}/${id}`).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }
}
