import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';
import { Equipe } from '@models/equipe';
import { Jeu } from '@models/jeu';
import { TypeJoueur } from '@models/type-joueur';
import { User } from '@models/user';
import { Store } from '@ngrx/store';
import { JeuService } from '@services/jeu.service';
import { UserService } from '@services/user.service';
import { MailService } from '@services/mail.service';

import { JoueurListItemComponent } from './joueur-list-item/joueur-list-item.component';

@Component({
  selector: 'app-joueur-list',
  templateUrl: './joueur-list.component.html',
  styleUrls: ['./joueur-list.component.scss']
})
export class JoueurListComponent implements OnInit {
  @ViewChildren(JoueurListItemComponent) joueurListItemComponents: QueryList<JoueurListItemComponent>;

  idJeu : number;
  jeu : Jeu;
  allEquipes : Equipe[];
  allJoueurs : any[] = [];
  allFreeUsers: any[] = [];
  assignedUsers: number[] = [];

  loadingSendMail = false;

  constructor(
    private route : ActivatedRoute,
    private store : Store,
    private jeuService : JeuService,
    private userService: UserService,
    private mailService: MailService
  ) { }

  ngOnInit(): void {
    this.idJeu = this.route.snapshot.params.idJeu;
    this.store.dispatch(jeuSelectAction({id : this.idJeu}));
    this.getAllJoueurs();
    this.getFreeUsers();
  }

  getAllJoueurs(){
    this.jeuService.getOne(this.idJeu).subscribe(
      (jeu)=>{

        this.jeu = jeu;
        this.allEquipes = this.jeu.equipes;

/***********************get allJoueurs ********************/
        this.allEquipes.forEach((equipe)=>{
          for(var i=0; i<equipe.joueurs.length; i++){
            equipe.joueurs[i]['equipe'] = equipe;
          }

			    this.allJoueurs = [...this.allJoueurs,...equipe.joueurs];
		    });

      }

    );
  }
  goTo(id: number){
	 return "/joueur/"+id+"/show";
  }


  getFreeUsers(){
    this.userService.getFreeUser().subscribe(
      (user)=>{
        this.allFreeUsers.push(user);

        this.allFreeUsers = this.allFreeUsers[0];
        // this.allFreeUsers.push({...this.joueur.user});
        console.log('allFreeusers', this.allFreeUsers);

      }
    );
  }

  onUserAssigned(userId: number){
    this.assignedUsers.push(userId);
  }

  onUserFreed(userId: number){
    if(userId){
      this.assignedUsers = this.assignedUsers.filter(x => x != userId);
    }
  }

  envoyerMail(){
    var emails = [];
    console.log('joueurlistItems', this.joueurListItemComponents)
    if(this.joueurListItemComponents){
      this.joueurListItemComponents.forEach(joueurListItemComponent => {
        let mailJoueur = joueurListItemComponent.getMailForSending();
        emails.push(mailJoueur);
      });
    }

    let data = {
      'emails': emails,
      'jeu': this.jeu.id
    };

    this.loadingSendMail = true;
    this.mailService.sendMailJoueurs(data).subscribe(() => {
      this.loadingSendMail = false;
    });

  }

  downloadPdfJoueurs(){
    this.jeuService.downloadPdfJoueurs(this.jeu.id).subscribe((blob: Blob): void => {
      const file = new Blob([blob], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      console.log(fileURL);
      // window.open(fileURL);
      var a         = document.createElement('a');
          a.href        = fileURL;
          a.target      = '_blank';
          a.download    = 'pdf_joueurs.pdf';
          document.body.appendChild(a);
          a.click();
    });
  }
}
