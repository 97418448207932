import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User, UserAdapter } from '@models/user';
import { AuthenticationService } from '@services/authentication.service';
import { MediaObjectService } from '@services/media-object.service';
import { UserService } from '@services/user.service';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { matchPasswordValidator} from "../validators/signup-validator";
import { Toast } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [MessageService]
})
export class SignupComponent implements OnInit{

  imageUrl: any = "";

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private mediaObjectService: MediaObjectService,
    private messageService: MessageService
  ) {
    this.imageUrl = this.mediaObjectService.contentUrl({});
  }

  verifyUsernameValidator=(userService:UserService,time:number=500)=>{
    return (input : FormControl)=>{
      return timer(time).pipe(
        switchMap(()=>userService.findUsername(input.value)),
        map(res=>{
          return res.length ? {usernameExist :true} :null
        })
      );
    }
  };

  isUsernameToken():boolean{
    return this.signupForm.get('username').hasError('usernameExist');
  }

  signupForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    username: new FormControl(null, Validators.required, this.verifyUsernameValidator(this.userService)),
    password: new FormControl('', Validators.required),
    confirm_password: new FormControl('', Validators.required),
    phone: new FormControl(""),
    organisation: new FormControl(""),
    description: new FormControl(""),
    photo_source: new FormControl("")
  },
  {
    validators: matchPasswordValidator
  });

  loading:boolean = false;
  errorNetwork = false;
  errorUnauthorized = false;

  ngOnInit(): void {
    this.toastSignupSuccess();
  }

  onSelectImage(e){
    if(e.target.files.length>0){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      }
      console.log(e.target.files[0]);
      this.signupForm.get('photo_source').setValue(e.target.files[0]);
    }
  }

  signup(){
    if(this.signupForm.valid){
      const formValue = this.signupForm.value;
      this.loading = true;
      if(formValue["photo_source"] == ""){
        const newUser =  {
          'lastname': formValue['lastname'],
          'firstname': formValue['firstname'],
          'username': formValue['username'],
          'password': formValue['password'],
          'phone' : formValue['phone'],
          'email': formValue['username'],
          'organisation' : formValue['organisation'],
          'description' : formValue['description'],
          'active': false,
        };
        this.authenticationService.signup(newUser).subscribe(
          (data)=>{
            this.goToLogin();
          },
          (error)=>{
            this.errorNetwork = false;
            this.errorUnauthorized = false;
            if(error.status == '401'){
              this.loading = false;
              this.errorUnauthorized = true;
            }else{
              this.errorNetwork = true;
              this.loading = false;
            }
          }
        );
      }
      else {
        var formData = new FormData();
        console.log(this.signupForm.get('photo_source').value);
        formData.append("file", this.signupForm.get('photo_source').value);
        // console.log(formData.getAll('file'));
        // formData.append('file2', this.signupForm.get('photo_source').value);
        // formData.append('file2', this.signupForm.get('photo_source').value);
      //   for (var key of formData.entries()) {
      //     console.log(key[0] + ', ' + key[1]);
      // }
        this.mediaObjectService.createMediaObject(formData).subscribe(
          (res) => {
            console.log(res);

            const newUser =  {
              'lastname': formValue['lastname'],
              'firstname': formValue['firstname'],
              'username': formValue['username'],
              'password': formValue['password'],
              // 'phone' : formValue['phone'],
              // 'email': formValue['username'],
              // 'organisation' : formValue['organisation'],
              // 'description' : formValue['description'],
              'photo' : res["@id"],
              'active': false,
            };
            console.log(newUser);
            this.authenticationService.signup(newUser).subscribe(
              (data)=>{
                this.goToLogin();
              },
              (error)=>{
                this.errorNetwork = false;
                this.errorUnauthorized = false;
                if(error.status == '401'){
                  this.loading = false;
                  this.errorUnauthorized = true;
                }else{
                  this.errorNetwork = true;
                  this.loading = false;
                }
              }
            );
          }
        )
      }

    }

  }

  toastSignupSuccess(){
    console.log('this toast signup success');
    this.messageService.add({ severity: 'success', summary: "Inscription validée", detail: "Votre inscription a été validée. Vous allez avoir un email de la part de l'administrateur lorsque votre compte sera activé."});
  }

  goToLogin(){
    this.router.navigate(['/login'], {
      state: {
        signupSuccess: true
      }
    });
  }
}
