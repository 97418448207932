import { MediaObject, MediaObjectAdapter } from './media-object';
import { Jeu, JeuAdapter } from './jeu';
import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
export interface BreakingNew {
    id?: number;
    tourApparition?: number;
    started?: boolean;
    dateLancement?: string;
    nom?: string;
    description?: string;
    fichier?: MediaObject;
    jeu?: Jeu | string;
    idJeu?: number;
    createur?: string
}
@Injectable({
    providedIn: 'root'
  })
  export class BreakingNewAdapter implements Adapter<BreakingNew>{
    constructor(
        private mediaObjectAdpter: MediaObjectAdapter,
        private jeuAdapter: JeuAdapter
        ){}
    adapt(data: any): BreakingNew {
      console.log(data);
      return {
        "id": data['id'],
        "tourApparition": data['tourApparition'],
        "started": data['started'],
        "dateLancement": data['dateLancement'],
        "nom": data['nom'],
        "description": data['description'],
        'fichier': data['fichier']? this.mediaObjectAdpter.adapt(data['fichier']) : null
      } as BreakingNew;
    }
  }