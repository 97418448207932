import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { Jeu } from '@models/jeu';
import { JeuService } from '@services/jeu.service';
import { Store, select } from '@ngrx/store';
import { playJeuAction, jeuSelectAction } from '@app/core/actions/jeu.actions';
import { Observable } from 'rxjs';
import { selectJeuId } from '@app/core/selectors/jeu.selector';
import { Router } from '@angular/router';
import { User } from '@models/user';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-page-list-jeu-user',
  templateUrl: './page-list-jeu-user.component.html',
  styleUrls: ['./page-list-jeu-user.component.scss']
})
export class PageListJeuUserComponent implements OnInit {
  listJeux: Jeu[];
  jeuxObservation: Jeu[];

  jeuId$: Observable<number>;
  user: User;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private jeuService: JeuService,
    private store: Store,
    private router: Router
  ) {

    this.store.pipe(select(selectJeuId)).subscribe(jeuId => {
      if(jeuId != 0){
        this.router.navigate(['/layout']);
      }
    });
  }

  ngOnInit(): void {
    this.getListJeuxUser();
    this.getListJeuxObservation();
    this.user = this.authenticationService.currentUserInfo as User;
  }

  getListJeuxUser(){
    this.jeuService.getListJeuxFuturs().subscribe(jeux => {
      this.listJeux = jeux;
    });
  }

  getListJeuxObservation(){
    this.jeuService.getListJeuxObservation().subscribe(jeux => {
      this.jeuxObservation = jeux;
    });
  }
  /**
   * Sélectionne le Jeu à jouer
   * - le met dans la base: jeuActuel du User
   *
   */
  playJeu(idJeu: number){
    this.store.dispatch(playJeuAction({ id: idJeu}));

    this.userService.playJeu(idJeu).subscribe();
  }

  observerJeu(idJeu: number){
    this.store.dispatch(playJeuAction({ id: idJeu}));

    this.userService.observerJeu(idJeu).subscribe(() => {
      console.log('observing jeu');
      this.router.navigate(['/layout/joueur/animateur']);
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
