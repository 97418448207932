<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>CSG</title>
</head>
<body>
  <div class="video-bg inscription">
    <video src="assets/images/bg-body.mp4" loop muted autoplay>
    </video>
    <div class="overlay"></div>
    <div class="container-custom login-back">
      <div class="login-form mt-3">
        <div class="logo-gm"><img src="assets/images/logo-blanc.png"></div>
        <div class="formulaire">
          <div class="titre-data">
            <h2><span class="light">S'inscrire</span> !</h2>
          </div>
          <div class="csg-form">
            <form [formGroup]="signupForm" (ngSubmit)="signup()">

              <div class="row">

                <div class="col-lg-6">

              <div class="form-label-group-custom">

                <input type="text" value="" name="lastname" id="inputName" class="form-control-custom" placeholder="Entrer votre nom" required="" autofocus="" formControlName="lastname">
                <i class="icon-email"></i>
                <label for="inputName">Nom *</label>
              </div>

              <div class="form-label-group-custom">

                <input type="text" value="" name="firstname" id="inputFirstName" class="form-control-custom" placeholder="Entrer votre prénom" required="" autofocus="" formControlName="firstname">
                <i class="icon-email"></i>
                <label for="inputFirstName">Prénoms *</label>
              </div>

              <div class="form-label-group-custom">

                <input type="email"
                        value=""
                        name="username"
                        id="inputEmail"
                        [ngClass]="{
                          'form-control-custom': true,
                          'border-error': isUsernameToken(),
                          'border-success': !isUsernameToken()
                                            && this.signupForm.get('username').value !== null
                                            && this.signupForm.get('username').value !== ''
                        }"
                        placeholder="Nom d'utilisateur"
                        required=""
                        autofocus=""
                        formControlName="username">
                <i class="icon-email"></i>
                <label for="inputEmail">Email *</label>
                <div class="error" *ngIf="isUsernameToken()">
                  * Cet email est déjà utilisé
                </div>
                <div  class="success"
                      *ngIf="!isUsernameToken()
                              && this.signupForm.get('username').value !== null
                              && this.signupForm.get('username').value !== ''
                            ">
                  * Email disponible
                </div>
              </div>

              <div class="form-label-group-custom">
                <input type="password" name="password" id="inputPassword" class="form-control-custom" placeholder="Mot de passe" required="" autofocus="" formControlName="password">
                <i class="icon-password"></i>
                <label for="inputPassword">Mot de passe *</label>
              </div>

              <div class="form-label-group-custom">
                <input type="password" name="password" id="inputConfirmPassword" [ngClass]="{'border-error': signupForm.errors?.passNotMatch}" class="form-control-custom" placeholder="Mot de passe" required="" autofocus="" formControlName="confirm_password">
                <i class="icon-password"></i>
                <label for="inputConfirmPassword">Confirmez le mot de passe *</label>
                <div class="error" *ngIf="signupForm.errors?.passNotMatch">
                  * Les mots de passes ne correspondent pas
                </div>
              </div>


              </div>


              <div class="col-lg-6">


              <div class="form-group">
                <label for="inputTelephone">Photo de profil</label>
                  <div>
                    <img class="user-photo" [src]="imageUrl" alt="logo" (click)="fileInput.click()">
                    <input type="file" class="form-control" id="inputTelephone" (change)="onSelectImage($event)" #fileInput hidden>
                  </div>
              </div>

              <div class="form-group">
                <label for="inputTelephone">Téléphone</label>
                <input type="text" class="form-control" id="inputTelephone" formControlName="phone" placeholder="Téléphone">
              </div>


              <div class="form-group">
                <label for="inputOrganisation">Organisation</label>
                <input type="text" class="form-control" id="inputOrganisation" formControlName="organisation" placeholder="Organisation">
              </div>
              <div class="form-group">
                <label for="Textarea1">A propos de moi</label>
                <textarea spellcheck="false" class="form-control" id="Textarea1" rows="3" formControlName="description"></textarea>
              </div>

              </div>

              <button type="submit" class="btn_gradient" [disabled]="signupForm.invalid">
                <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>
                S'INSCRIRE
              </button>


              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

  <p-toast position="top-left"></p-toast>
</body>
</html>
