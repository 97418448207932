import { BreakingNew } from '@models/breakingNew';
import { createAction, props } from '@ngrx/store';
import { Jeu } from '@models/jeu';
import { Joueur } from '@models/joueur';
import { Historique } from '@models/historique';
import { Budget } from '@models/budget';
import { DebriefElement } from '@models/debrief-element';

export const selectAppJeuAction = createAction(
  '[App] SELECT_APP_JEU',
  props<{jeu: Jeu}>()
);

export const selectAppJoueurAction = createAction(
  '[App] SELECT_APP_JOUEUR',
  props<{joueur: Joueur}>()
);

export const initAppHistoriquesAction = createAction(
  '[App] INIT_APP_HISTORIQUES',
  props<{historiques: Historique[]}>()
);

export const addAppHistoriqueAction = createAction(
  '[App] ADD_APP_HISTORIQUE',
  props<{historique: Historique}>()
);

export const initAppDebriefElementAction = createAction(
  '[App] INIT_APP_DEBRIEF_ELEMENT',
  props<{debriefElements: DebriefElement[]}>()
);

export const addAppDebriefElementAction = createAction(
  '[App] ADD_APP_DEBRIEF_ELEMENT_ACTION',
  props<{debriefElement: DebriefElement}>()
);

export const initAppBudgetAction = createAction(
  '[App] INIT_APP_DEPENSE',
  props<{ budget: Budget}>()
);

export const appAddDepensesAction = createAction(
  '[App] APP_ADD_DEPENSE',
  props<{ depenses: number}>()
);

export const appRemoveDepensesAction = createAction(
  '[App] APP_REMOVE_DEPENSE',
  props<{ depenses: number}>()
);

/**
 * On a un dictionnaire pour toutes les lignes de dépenses. Cet action va recalculer automatiquement les dépenses
 */
export const appSetLigneDepenseAction = createAction(
  '[App] SET_LIGNE_DEPENSE',
  props<{ ligne: string, depense: number}>()
);


export const loadResultatAction = createAction(
  '[App] LOAD_RESULTAT'
);

export const loadDecisionsAction = createAction(
  '[App] LOAD_DECISIONS'
);

export const startPitchAction = createAction(
  '[App] START_PITCH'
);

export const loadVoteAction = createAction(
  '[App] LOAD_VOTE'
);

export const loadSondageAction = createAction(
  '[App] LOAD_SONDAGE'
);

export const changementVoteAction = createAction(
  '[App] CHANGEMENT_VOTE'
);

export const changementNouveauxProduitsAction = createAction(
  '[App] CHANGEMENT_NOUVEAUX_PRODUITS'
);

export const virementAction = createAction(
  '[App] VIREMENT'
);

export const etapeSuivanteAction = createAction(
  '[App] ETAPE_SUIVANTE'
);

export const setUnseenAction = createAction(
  '[App] SET_UNSEEN_ROOM_MESSAGE',
  props<{roomId: number}>()
);

export const setSeenRoomAction = createAction(
  '[App] SET_SEEN_ROOM_MESSAGE',
  props<{roomId: number}>()
);

export const changementCoequipierSondageAction = createAction(
    '[App] CHANGEMENT_COEQUIPIER_SONDAGE'
);

export const popBreakingNewAction = createAction(
  '[App] POP_BREAKING_NEW',
  props<{breakingNew: BreakingNew}>()
);
