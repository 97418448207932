import { MailService } from './../../../../../services/mail.service';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Joueur } from '@models/joueur';
import { FormControl } from '@angular/forms';
import { JoueurService } from '@services/joueur.service';
import { User } from '@models/user';
import { Jeu } from '@models/jeu';

@Component({
  selector: 'app-joueur-list-item',
  templateUrl: './joueur-list-item.component.html',
  styleUrls: ['./joueur-list-item.component.scss']
})
export class JoueurListItemComponent implements OnInit, OnChanges {
  @Input() joueur: Joueur;
  @Input() jeu: Jeu;
  @Input() allFreeUsers: any[] = [];
  @Input() assignedUsers: number[] = [];

  @Input() canEdit: boolean = false;

  @Output() userAssigned = new EventEmitter<number>();
  @Output() userFreed = new EventEmitter<number>();

  joueurReel = new FormControl();
  loadingAssignJoueur = false;
  loadingChangementJoueur = false;
  lastUserAssigned: number;
  emailSending = new FormControl('');
  loadingSendMail = false;

  constructor(
    private joueurService: JoueurService,
    private mailService: MailService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void{

  }

  initForm(){

    if(this.joueur.user){
      this.joueurReel.setValue(this.joueur.user.id);
      this.lastUserAssigned = this.joueur.user.id;

      console.log(this.joueur.user.email);
      this.emailSending.setValue(this.joueur.user.email);
      this.userAssigned.emit(this.joueur.user.id);
    }
  }

  goTo(id: number){
    return "/joueur/"+id+"/show";
  }

  assignJoueur(){
    let joueurReelId = this.joueurReel.value;

    console.log('joueurReelId', joueurReelId);

    // Prefill email
    let joueurReel = this.allFreeUsers.filter(user => {
      return user.id == joueurReelId;
    });

    let userEmail = null;
    console.log(joueurReel);
    if(joueurReel.length != 0){
      userEmail = joueurReel[0].email;
    }else{
      userEmail = '';
    }

    console.log('user email', userEmail);
    this.emailSending.setValue(userEmail);

    this.loadingAssignJoueur = true;

    this.userAssigned.emit(joueurReelId);
    this.userFreed.emit(this.lastUserAssigned);

    this.joueurService.assignJoueurReel(this.joueur.id, joueurReelId).subscribe(() => {
      this.loadingAssignJoueur = false;
      this.lastUserAssigned = joueurReelId;
    }, error => {
      this.userAssigned.emit(this.lastUserAssigned);
      this.userFreed.emit(joueurReelId);
    });
  }

  getMailForSending(){


    return [this.joueur.id, this.emailSending.value];
  }

  sendMailToJoueur(){
    var emails = [[this.joueur.id, this.emailSending.value]];

    let data = {
      'emails': emails,
      'jeu': this.jeu.id
    };

    this.loadingSendMail = true;
    this.mailService.sendMailJoueurs(data).subscribe(() => {
      this.loadingSendMail = false;
    });
  }
}
