import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { CustomDatasource } from '@datasources/custom-datasource';
import { Jeu, JeuAdapter } from '@models/jeu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { JeuLister } from '@services/listers/jeu-lister';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { JeuService } from '@services/jeu.service';
import { merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { jeuSelectAction } from '@app/core/actions/jeu.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tableau-list-jeu',
  templateUrl: './tableau-list-jeu.component.html',
  styleUrls: ['./tableau-list-jeu.component.scss'],
  providers: [JeuLister]
})
export class TableauListJeuComponent implements OnInit, AfterViewInit {
  @Input() isAdmin: boolean;
  @Input() isAnimateur: boolean;
  @Input() isAnimation: boolean; // true si c'est déjà une animation
  @Input() isTemplate: boolean; // true si le jeu est un template
  @Input() isFinished: boolean;

  @Output('selectedJeu') selectedJeu = new EventEmitter<number>();

  dataSourceT: CustomDatasource<Jeu>;
  displayedColumns = ["nom_jeu", "action"];
  defaultPageSize = 5;
  closeModal: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get isEnCours(){
    return this.isAnimation && !this.isFinished;
  }

  loadingReplay: number = 0;

  constructor(
    private jeuLister: JeuLister,
    private adapter: JeuAdapter,
    private modalService : NgbModal,
    private jeuService : JeuService,
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dataSourceT = new CustomDatasource<Jeu>(this.jeuLister, this.adapter);

    let filter = this.generateFilter();

    // this.dataSource.load(filter,null,this.defaultPageSize);
    // this.load();
  }

  ngAfterViewInit(): void {
    this.load();

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      debounceTime(500),
      tap(() => {
        console.log('load from merge');
        this.load();
      })
    ).subscribe();
  }

  ngOnChanges(): void {
    console.log('changes');
    this.load();
  }

  load(){
    let orders = {};

    if(this.sort){

      let sortCol = this.sort.active;
      let sortDirection = this.sort.direction;
      orders = {[sortCol]: sortDirection};
    }

    let filter = this.generateFilter();

    console.log('filters', filter);

    if(this.dataSourceT){
      this.dataSourceT.load(filter, this.paginator.pageIndex, this.paginator.pageSize, orders);
    }
  }

  /************Modal setting*************/
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  onDelete(id: number, state: boolean){
    this.jeuService.delete(id).subscribe(
      () => {
        // this.dataSource.load({finished: false},null,this.defaultPageSize)
        this.load();
      }
    );
  }

  onSelectJeu(id: number){
    this.selectedJeu.emit(id);
    this.store.dispatch(jeuSelectAction({id: id}));
  }

  goToAnimerJeu(id: number){
    this.router.navigate([`/jeux/edit-animation/${id}`]);
  }

  generateFilter(){
    let filter = {};

    if(!this.isAnimation){
      filter['isAnimation'] = null;
      filter['finished'] = false;
      filter['started'] = false;
    }else if(this.isEnCours){
      console.log('this.encours is here');
      // filter['started'] = true;
      filter['isAnimation'] = true;
      filter['finished'] = false;
    }else if(this.isFinished){
      filter['finished'] = true;
    }

    return {...filter};
  }


  replayGame(id: number){
    this.loadingReplay = id;
    this.jeuService.cloner(id).subscribe(jeu => {
      this.router.navigate([`/jeux/designer/creation/${jeu.id}`]);
      // this.loadingReplay = tr;
    });
  }
}
