import { BreakingNew } from '@models/breakingNew';
import { Equipe, EquipeAdapter } from './equipe';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ActionJeu } from '@models/action-jeu';
import { Annonce } from './annonce';
export interface Jeu{
  id: number,
  iri: string,
  nom: string,
  description: string,
  debut?: string,
  debutReel?: string,
  nbJoueurs: number,
  tour?: number,
  nbTours:number,
  finished: boolean,
  started: boolean,
  equipes: Equipe[] | any[],
  animateurJeus : any[],
  breakingNews: BreakingNew[],
  currentBreakingNew: BreakingNew,
  isExemple: boolean,
  currentAction?: ActionJeu,
  currentAnnonce?: Annonce,
  minutage?: Object | Array<Object>,
  equivalentPeriode?: string,
  langue?: string,
  isWarmup?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class JeuAdapter implements Adapter<Jeu>{
  constructor(private equipeAdapter: EquipeAdapter){}
  adaptCustom(data: any): Jeu{
    return {
      "id": data['id'],
      "iri": data['@id'],
      "nom": data['nom'],
      "description": data['description'],
      "type": data['type'],
      "nbJoueurs": data['nbJoueurs'],
      "nbTours": data['nbTours'],
      "tour": data['tour'],
      "debut": data['debut'],
      "debutReel": data['debutReel'],
      "finished": data['finished'],
      "started": data['started'],
      "equipes": data['equipes'],
      "animateurJeus": data['animateurJeus'],
      "breakingNews": data['breakingNews'],
      "isExemple": data['isExemple'],
      "currentAction": data['currentAction'] as ActionJeu,
      "currentBreakingNew": data['currentBreakingNew'] ? data['currentBreakingNew'] as BreakingNew : null,
      "equivalentPeriode": data['equivalentPeriode'],
      "langue": data['langue'],
      "minutage": data['minutages'],
      "isWarmup": data['isWarmup'],
      "hasWarmup": data['hasWarmup']
    } as Jeu;
  }

  adapt(data: any): Jeu {
    return {
      "id": data['id'],
      "iri": data['@id'],
      "nom": data['nom'],
      "description": data['description'],
      "type": data['type'],
      "nbJoueurs": data['nbJoueurs'],
      "nbTours": data['nbTours'],
      "tour": data['tour'],
      "debut": data['debut'],
      "debutReel": data['debutReel'],
      "finished": data['finished'],
      "started": data['started'],
      "equipes": data['equipes'],
      "animateurJeus": data['animateurJeus'],
      "breakingNews": data['breakingNews'],
      "isExemple": data['isExemple'],
      "currentAction": data['currentAction'] as ActionJeu,
      "currentBreakingNew": data['currentBreakingNew'] ? data['currentBreakingNew'] as BreakingNew : null,
      "equivalentPeriode": data['equivalentPeriode'],
      "langue": data['langue'],
      "minutage": data['minutages'],
      "isWarmup": data['isWarmup'],
      "hasWarmup": data['hasWarmup']
    } as Jeu;
  }

  adaptCurrentAction(data: any): ActionJeu{
    return {
      'id': data['id'],
      'type': data['type'],
      'slug': data['slug'],
      'duree': data['duree'],
      'ordre': data['ordre'],
      'started': data['started'],
      'finished': data['finished'],
      'dateLancement': data['dateLancement'],
      'timePassed': data['timePassed'],
      'nextAction': data['nextAction'] ? data['nextAction'] as ActionJeu : null,
      'parentAction': data['currentAction'] ? data['currentAction'] as ActionJeu : null,
      'currentAnnonce': data['currentAnnonce'] ? data['currentAnnonce'] as Annonce : null,
      "currentBreakingNew": data['currentBreakingNew'] ? data['currentBreakingNew'] as BreakingNew : null,
      "equivalentPeriode": data['equivalentPeriode'],
      "langue": data['langue'],
      "minutage": data['minutages'],
      "isWarmup": data['isWarmup'],
      "hasWarmup": data['hasWarmup']
    } as ActionJeu;
  }
}
