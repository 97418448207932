import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export interface Room{
  id?: number,
  nom?: string,
  type?: string,
  logoPath?: string
}


@Injectable({
  providedIn: 'root'
})
export class RoomAdapter implements Adapter<Room>{
    constructor(

    ){}
    adapt(data: any): Room{
    const room = {
      'id': data['id'],
      'nom': data['nom'],
      'type': data['type'],
      'logoPath': data['logoPath']
    }
    return room as Room;
  }

}
